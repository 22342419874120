import {
    Box,
    FlexScrollArea,
    FlexScrollAreaContainer,
    IconAvatar,
    NavigationRailCell,
    SlackIntegrationCard,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import {
    ActionKeyOutline,
    BigGeoLogo,
    DatabaseOutline,
    HomeOutline,
    KeyOutline,
    PublicOutline,
    SupportOutline,
} from "@biggeo/bg-ui/lab/icons";
import { Link, useLocation } from "react-router-dom";
import { OnlyAvailableOnSnowflakePanel } from "../common/components/OnlyAvailableOnSnowflakePanel.tsx";
import { isAppRunningOnSF, isSnp } from "../common/redux/hooks.ts";
import { PortalMenu } from "../home/views/PortalMenu.tsx";
import { Routes } from "../navigation/redux/model.ts";
import ComputeMemorySpaceCard from "./ComputeMemorySpaceCard.tsx";

export type SidebarProps = {
    onItemClick?: () => void;
    isTemporary?: boolean;
};

export const Sidebar = ({ onItemClick, isTemporary }: SidebarProps) => {
    const path = useLocation().pathname;
    const routes = path.split("/");
    const isSNP = isSnp();
    const isRunningOnSF = isAppRunningOnSF();

    const checkSelectedRoute = (route: string) =>
        routes.indexOf(route.replace("/", "")) === 1;

    return (
        <FlexScrollAreaContainer>
            <FlexScrollArea
                sx={{
                    flexDirection: "column",
                    gap: 2,
                    paddingX: 3,
                    paddingTop: 4,
                    backgroundColor: (theme) => theme.palette.background.main,
                    borderRadius: (theme) => theme.radius.default,
                }}
            >
                <PortalMenu />
                <Stack>
                    <Link to={Routes.home} tabIndex={-1}>
                        <NavigationRailCell
                            startNode={<HomeOutline size="xs" />}
                            selected={path === Routes.home}
                            onClick={onItemClick}
                        >
                            Home
                        </NavigationRailCell>
                    </Link>
                    <Link to={Routes.exploreMapView} tabIndex={-1}>
                        <NavigationRailCell
                            startNode={<ActionKeyOutline size="xs" />}
                            selected={checkSelectedRoute(Routes.explore)}
                            onClick={onItemClick}
                        >
                            Explore
                        </NavigationRailCell>
                    </Link>
                </Stack>
                <Typography
                    variant="body4"
                    fontWeight="semibold"
                    color="disabled"
                    colorSet="container"
                    invertColors
                    sx={{
                        paddingLeft: 2,
                        cursor: "default",
                    }}
                >
                    Datascape
                </Typography>
                <Stack>
                    <Link
                        to={
                            isRunningOnSF
                                ? Routes.accessKeys
                                : Routes.onlyInSnowflake
                        }
                        tabIndex={-1}
                    >
                        <NavigationRailCell
                            startNode={<KeyOutline size="xs" />}
                            selected={checkSelectedRoute(Routes.accessKeys)}
                            onClick={onItemClick}
                        >
                            Access Keys
                        </NavigationRailCell>
                    </Link>
                    <Link to={Routes.mapTemplates} tabIndex={-1}>
                        <NavigationRailCell
                            startNode={<PublicOutline size="xs" />}
                            selected={checkSelectedRoute(Routes.mapTemplates)}
                            onClick={onItemClick}
                        >
                            Map Templates
                        </NavigationRailCell>
                    </Link>
                    <Link to={`${Routes.data}/available`} tabIndex={-1}>
                        <NavigationRailCell
                            startNode={<DatabaseOutline size="xs" />}
                            selected={checkSelectedRoute(Routes.data)}
                            onClick={onItemClick}
                        >
                            Manage Data
                        </NavigationRailCell>
                    </Link>
                    {/* <Link to={Routes.recipes}>
                            <NavigationRailCell
                                startNode={<ExperimentOutline size="xs" />}
                                selected={startsWith(path, Routes.recipes)}
                                onClick={onItemClick}
                            >
                                Recipes
                            </NavigationRailCell>
                        </Link> */}
                    {/* <Link to={Routes.team}>
                            <NavigationRailCell
                                startNode={<GroupsOutline size="xs" />}
                                selected={path === Routes.team}
                                onClick={onItemClick}
                            >
                                Team
                            </NavigationRailCell>
                        </Link> */}
                </Stack>
            </FlexScrollArea>
            <Stack
                gap={2}
                sx={{
                    padding: 3,
                }}
            >
                <Stack>
                    <Link to={Routes.support} target="_blank" tabIndex={-1}>
                        <NavigationRailCell
                            startNode={<SupportOutline size="xs" />}
                            onClick={onItemClick}
                        >
                            Help Center
                        </NavigationRailCell>
                    </Link>
                </Stack>
                {isRunningOnSF && <ComputeMemorySpaceCard />}
                {/* this component will be hidden until BE is ready
                <UpgradeBGVelocityCard
                    startAvatarURL={
                        "https://biggeo.blob.core.windows.net/media/fdsa.png"
                    }
                /> */}
                <OnlyAvailableOnSnowflakePanel />
                {isTemporary && (
                    <Link to={Routes.joinBigGeoSlack} target="_blank">
                        <SlackIntegrationCard />
                    </Link>
                )}
            </Stack>
            {isSNP && (
                <Box sx={{ padding: 3 }}>
                    <Stack
                        flexDirection="row"
                        alignItems="center"
                        sx={{
                            padding: 1,
                            backgroundColor: (theme) =>
                                theme.palette.primary.container,
                            borderRadius: (theme) => theme.radius.default,
                        }}
                        gap={1}
                    >
                        <IconAvatar
                            square
                            size="sm"
                            sx={{
                                border: (theme) =>
                                    `${theme.spacing(0.3)} solid ${theme.palette.surface.onMain}`,
                            }}
                        >
                            <BigGeoLogo size="sm" />
                        </IconAvatar>
                        <Typography variant="body3">
                            Powered by BigGeo
                        </Typography>
                    </Stack>
                </Box>
            )}
        </FlexScrollAreaContainer>
    );
};
