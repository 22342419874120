import {
    DataSourceExport,
    usePrepareDataSourcesForExportQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    LoadingBar,
    Severity,
    Stack,
    StickyFabPlacementHelper,
    SubmittingButton,
} from "@biggeo/bg-ui/lab";
import { match as vtMatch } from "@vividtheory/remotedata";
import { Formik } from "formik";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { isAppRunningOnSF } from "../../common/redux/hooks";
import { useMap } from "../../map/mapbox/context";
import { toasterActions } from "../../toaster/containers/redux/model";
import { getFiltersForExport } from "../../utils/utils";
import { DatasetCardList } from "../components/DatasetCardList";
import { ExportToSFHeader } from "../views/ExportToSFHeader";

export const ExportToSFContainer = ({
    onSubmit,
    loading,
}: {
    onSubmit: (data: string[]) => void;
    loading: boolean;
}) => {
    const dispatch = useDispatch();
    const isRunningOnSF = isAppRunningOnSF();
    const { datasets, filters } = useMap();
    const initialValues: string[] = [];
    const [localDatalist, setLocalDatalist] = useState<DataSourceExport[]>([]);
    const [preparingExport, setPreparingExport] = useState<boolean>(false);

    const { remote: exportRd } = usePrepareDataSourcesForExportQuery({
        variables: {
            input: {
                filters: getFiltersForExport({ datasets, filters }),
            },
        },
        onCompleted: ({ prepareDataSourcesForExport }) => {
            setPreparingExport(false);
            pipe(
                prepareDataSourcesForExport,
                O.fromPredicate((x) => !isEmpty(x)),
                O.foldW(
                    () => {},
                    (data) => {
                        setLocalDatalist(data);
                    }
                )
            );
        },
    });

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        vtMatch(exportRd, {
            _: () => {
                setPreparingExport(true);
            },
            Success: ({ prepareDataSourcesForExport }) => {
                pipe(
                    prepareDataSourcesForExport,
                    O.fromPredicate((x) => !isEmpty(x)),
                    O.foldW(
                        () => {},
                        (data) => {
                            setLocalDatalist(data);
                        }
                    )
                );
            },
            Failure: (e) => {
                dispatch(
                    toasterActions.openToast({
                        open: true,
                        severity: Severity.error,
                        title: e.message || "Error preparing for export",
                        autoHideDuration: 5000,
                    })
                );
            },
        });
    }, []);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={toFormikValidationSchema(z.string().array())}
            onSubmit={(values) => {
                onSubmit(values);
            }}
            validateOnMount
            enableReinitialize
        >
            {({ values, dirty, isValid, handleSubmit, setValues }) => {
                return (
                    <Stack gap={4}>
                        <ExportToSFHeader isRunningOnSF={isRunningOnSF} />
                        {preparingExport ? (
                            <LoadingBar />
                        ) : (
                            <DatasetCardList
                                dataList={localDatalist}
                                onSelect={(i) => {
                                    setValues((prev) => {
                                        // TODO: On Alfath's request, only allowing users to export one dataset at a time for now
                                        // return updateSelected(i, prev);
                                        return prev.includes(i) ? [] : [i];
                                    });
                                }}
                                selectedIds={values}
                            />
                        )}
                        <StickyFabPlacementHelper
                            placement="right"
                            sx={{
                                paddingY: 4,
                                backgroundColor: (theme) =>
                                    theme.palette.background.container,
                            }}
                        >
                            <Stack
                                flexDirection="row"
                                justifyContent="flex-end"
                            >
                                <SubmittingButton
                                    type="exporting"
                                    loading={loading}
                                    disabled={
                                        !isValid || !dirty || isEmpty(values)
                                    }
                                    onClick={() => handleSubmit()}
                                >
                                    Export Dataset
                                </SubmittingButton>
                            </Stack>
                        </StickyFabPlacementHelper>
                    </Stack>
                );
            }}
        </Formik>
    );
};
