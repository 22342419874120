import { ReqOptions } from "@biggeo/bg-server-lib/datascape-ai";
import {
    Checkbox,
    InfoCell,
    Slider,
    Stack,
    Tooltip,
    Typography,
} from "@biggeo/bg-ui/lab";
import { Info } from "@biggeo/bg-ui/lab/icons";
import { isAppRunningOnSF } from "../common/redux/hooks";

const DebugContainer = ({
    options,
    setOptions,
    showFiltered,
    showPoints,
    showTriangles,
    setShowFiltered,
    setShowPoints,
    setShowTriangles,
}: {
    options: ReqOptions;
    setOptions: (p: ReqOptions) => void;
    showTriangles: boolean;
    showPoints: boolean;
    showFiltered: boolean;
    setShowFiltered: (b: boolean) => void;
    setShowPoints: (b: boolean) => void;
    setShowTriangles: (b: boolean) => void;
}) => {
    const isRunningOnSF = isAppRunningOnSF();
    return (
        <Stack gap={4} width="100%" sx={{ padding: 4 }}>
            <Stack
                flexDirection="row"
                gap={1}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Typography variant="title3" fontWeight="bold">
                    Points
                </Typography>
                <Tooltip
                    sx={{ maxWidth: 50 }}
                    title={
                        <Stack gap={2}>
                            <Stack gap={1}>
                                <Typography variant="body2" fontWeight="bold">
                                    Maximum Geometries
                                </Typography>
                                <Typography variant="body3">
                                    This parameter describes the maximum number
                                    of geometries before BG-Search returns an
                                    aggregate view for that cell.
                                </Typography>
                            </Stack>
                            <Stack gap={1}>
                                <Typography variant="body2" fontWeight="bold">
                                    Resolution Offset
                                </Typography>
                                <Typography variant="body3">
                                    This parameter helps control the resolution
                                    of the aggregated cells returned.
                                </Typography>
                            </Stack>
                        </Stack>
                    }
                >
                    <span>
                        <Info color={"info"} size="sm" />
                    </span>
                </Tooltip>
            </Stack>
            <Stack gap={2}>
                <Typography variant="title4" fontWeight="bold">
                    Maximum Geometries{" "}
                    {isRunningOnSF
                        ? `(${options.pointMaxGeomsPerCell || 200})`
                        : `(${options.pointMaxGeomsPerCell || 101})`}
                </Typography>
                <Slider
                    color="surface"
                    rangeMin={1}
                    rangeMax={isRunningOnSF ? 300 : 200}
                    value={
                        isRunningOnSF
                            ? options.pointMaxGeomsPerCell || 200
                            : options.pointMaxGeomsPerCell || 101
                    }
                    onDrag={(_e, v) => {
                        setOptions({
                            ...options,
                            pointMaxGeomsPerCell: v,
                        });
                    }}
                />
            </Stack>
            <Stack gap={2}>
                <Typography variant="title4" fontWeight="bold">
                    Resolution Offset{" "}
                    {isRunningOnSF
                        ? `(${options.pointResolutionOffset || 3})`
                        : `(${options.pointResolutionOffset || 3})`}
                </Typography>
                <Slider
                    rangeMin={1}
                    rangeMax={isRunningOnSF ? 6 : 4}
                    value={
                        isRunningOnSF
                            ? options.pointResolutionOffset || 3
                            : options.pointResolutionOffset || 3
                    }
                    onDrag={(_e, v) => {
                        setOptions({
                            ...options,
                            pointResolutionOffset: v,
                        });
                    }}
                />
            </Stack>
            <Typography variant="title3" fontWeight="bold">
                Polygons
            </Typography>
            <Stack gap={2}>
                <Typography variant="title4" fontWeight="bold">
                    Maximum Geometries{" "}
                    {isRunningOnSF
                        ? `(${options.polygonMaxGeomsPerCell || 125})`
                        : `(${options.polygonMaxGeomsPerCell || 75})`}
                </Typography>
                <Slider
                    rangeMin={1}
                    rangeMax={isRunningOnSF ? 300 : 200}
                    value={
                        isRunningOnSF
                            ? options.polygonMaxGeomsPerCell || 125
                            : options.polygonMaxGeomsPerCell || 75
                    }
                    onDrag={(_e, v) => {
                        setOptions({
                            ...options,
                            polygonMaxGeomsPerCell: v,
                        });
                    }}
                />
            </Stack>
            <Stack gap={2}>
                <Typography variant="title4" fontWeight="bold">
                    Resolution Offset{" "}
                    {isRunningOnSF
                        ? `(${options.polygonResolutionOffset || 3})`
                        : `(${options.polygonResolutionOffset || 3})`}
                </Typography>
                <Slider
                    rangeMin={1}
                    rangeMax={isRunningOnSF ? 7 : 4}
                    value={
                        isRunningOnSF
                            ? options.polygonResolutionOffset || 3
                            : options.polygonResolutionOffset || 3
                    }
                    onDrag={(_e, v) => {
                        setOptions({
                            ...options,
                            polygonResolutionOffset: v,
                        });
                    }}
                />
            </Stack>
            <Stack gap={4} sx={{ paddingTop: 2 }}>
                <InfoCell
                    title={"Show Level Sets"}
                    density="none"
                    startNode={
                        <Checkbox
                            checked={showTriangles}
                            onClick={() => {
                                setShowTriangles(!showTriangles);
                            }}
                        />
                    }
                />
                <InfoCell
                    title={"Show Points"}
                    density="none"
                    startNode={
                        <Checkbox
                            checked={showPoints}
                            onClick={() => {
                                setShowPoints(!showPoints);
                            }}
                        />
                    }
                />
                <InfoCell
                    title={"Show Filtered"}
                    density="none"
                    startNode={
                        <Checkbox
                            checked={showFiltered}
                            onClick={() => {
                                setShowFiltered(!showFiltered);
                            }}
                        />
                    }
                />
            </Stack>
        </Stack>
    );
};

export default DebugContainer;
