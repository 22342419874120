import { ApolloQueryResult } from "@apollo/client";
import {
    DatabaseType,
    Exact,
    FetchAllAreasExtendedQuery,
    FetchSavedViewsQuery,
    FilterObject,
    InputViewBox,
    PointDataInput,
    ReqOptions,
    SavedArea,
    SubscriptionResponse,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    Box,
    Button,
    FlexScrollArea,
    FlexScrollAreaContainer,
    Grid,
    HorizontalScroller,
    SelectableTreeMenuItem,
    TopAppBar,
    Typography,
} from "@biggeo/bg-ui/lab";
import { CloseOutline } from "@biggeo/bg-ui/lab/icons";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { compose } from "redux";
import { isAppRunningOnSF, isSnp } from "../common/redux/hooks.ts";
import type { Consumers } from "../common/redux/model.ts";
import { commonActions } from "../common/redux/model.ts";
import { ComingSoonPage } from "../components/ComingSoon/ComingSoonPage.tsx";
import type { DatasetPointShape } from "../components/DatapointShape/types.ts";
import DatasetChip from "../data-sets/DatasetChip.tsx";
import DataContainer from "../map/DataContainer.tsx";
import ExportContainer from "../map/containers/ExportContainer.tsx";
import { MapSubMenu } from "../map/containers/MapSubMenu";
import type { InputPolygonWithId } from "../map/hooks/pure-data-string-hook.ts";
import { useMap } from "../map/mapbox/context/map.ts";
import {
    FunctionType,
    handleFilterReset,
    pickGeospatialSelection,
} from "../map/utils/utils";
import { modalActions } from "../modal/redux/model.tsx";

type MapLayoutProps = {
    readonly children: React.FC<{
        readonly filterItems: readonly SelectableTreeMenuItem[];
        readonly setFilterItems: React.Dispatch<
            React.SetStateAction<readonly SelectableTreeMenuItem[]>
        >;
        readonly resetAreasFilters: () => void;
        readonly handleSideMenu: (v: boolean) => void;
    }>;
    readonly recentResponse?: SubscriptionResponse;
    readonly setFunctionType: (v: FunctionType) => void;
    readonly responses: Partial<Record<string, SubscriptionResponse | null>>;
    readonly multiFilters: FilterObject[];
    readonly selectedPoint: PointDataInput | undefined;
    readonly setSelectedPoint: (p: PointDataInput | undefined) => void;
    readonly addRemoveDataset: (v: string) => void;
    readonly selectedDataset: readonly string[];
    readonly setSelectedDataset: (datasets: readonly string[]) => void;
    readonly setSavedPolygons: (p?: readonly SavedArea[]) => void;
    readonly options: ReqOptions;
    readonly setOptions: (p: ReqOptions) => void;
    readonly activeConsumption: Consumers;
    readonly changeConsumption: (c: Consumers) => void;
    readonly showTriangles: boolean;
    readonly showPoints: boolean;
    readonly showFiltered: boolean;
    readonly setShowFiltered: (b: boolean) => void;
    readonly setShowPoints: (b: boolean) => void;
    readonly setShowTriangles: (b: boolean) => void;
    readonly clearShapes?: () => void;
    readonly reFetchSavedAreas?: boolean;
    readonly selectedAreaId?: number;
    readonly setReFetchSavedAreas?: (value: boolean) => void;
    readonly setMultiFilters: (mf: FilterObject[]) => void;
    readonly channelId: string;
    readonly functionType: FunctionType;
    readonly viewport: InputViewBox;
    readonly polygons?: InputPolygonWithId[];
    readonly setOpenSaveViewPopper?: (value: boolean) => void;
    readonly datasetShape?: readonly DatasetPointShape[];
    readonly setDatasetShape?: (datasetShape: DatasetPointShape) => void;
    readonly selectedSavedView?: number;
    readonly setSelectedSavedView: (id: number) => void;
    readonly handleSavedPolygons: (p: InputPolygonWithId[]) => void;
    readonly savedAreasData: FetchAllAreasExtendedQuery | undefined;
    readonly refetchSavedAreas?: (
        variables?:
            | Partial<
                  Exact<{
                      [key: string]: never;
                  }>
              >
            | undefined
    ) => Promise<ApolloQueryResult<FetchAllAreasExtendedQuery>>;
    readonly savedViewsData: FetchSavedViewsQuery | undefined;
    readonly loadingSavedViews: boolean;
};

export const MapLayout = ({
    showTriangles,
    showPoints,
    showFiltered,
    setShowFiltered,
    setShowPoints,
    setShowTriangles,
    activeConsumption,
    changeConsumption,
    options,
    children,
    setFunctionType,
    multiFilters,
    addRemoveDataset,
    selectedDataset,
    setSelectedDataset,
    setSavedPolygons,
    setOptions,
    clearShapes,
    reFetchSavedAreas,
    setReFetchSavedAreas,
    setMultiFilters,
    channelId,
    functionType,
    viewport,
    polygons,
    setOpenSaveViewPopper,
    selectedAreaId,
    datasetShape,
    setDatasetShape,
    selectedSavedView,
    setSelectedSavedView,
    handleSavedPolygons,
    savedAreasData,
    refetchSavedAreas,
    savedViewsData,
    loadingSavedViews,
    recentResponse,
    responses,
}: MapLayoutProps) => {
    const dispatch = useDispatch();
    const snp = isSnp();

    const { datasets } = useMap();
    const closeModal = compose(dispatch, modalActions.closeModal);
    const geospatialSelection = pickGeospatialSelection(functionType, {
        viewport,
        multipolygon: polygons?.map((c) => ({
            inners: c.inners,
            outer: c.outer,
        })),
    });
    const toPage = useNavigate();
    const isRunningOnSF = isAppRunningOnSF();

    const [menuOpen, setMenuOpen] = useState(false);
    const [filterItems, setFilterItems] = useState<
        readonly SelectableTreeMenuItem[]
    >([]);

    const hasChanges = !isEmpty(polygons) || !isEmpty(selectedDataset);

    const resetAreasFilters = () =>
        setFilterItems(handleFilterReset(filterItems));

    const handleDatasetChipClick = (
        databaseId: string,
        selectedChip: string,
        geographyColumn: string,
        hasTableId: boolean,
        dataType?: string
    ) => {
        dispatch(
            modalActions.openModal({
                modalType: "dialog",
                component: pipe(
                    dataType,
                    O.fromPredicate((x) => x !== DatabaseType.polygon),
                    O.fold(
                        () => (
                            <ComingSoonPage
                                dataType={dataType}
                                isRunningOnSF={isRunningOnSF}
                                onClose={closeModal}
                            />
                        ),
                        () => (
                            <FlexScrollAreaContainer height={"100%"}>
                                <TopAppBar
                                    density="dense"
                                    endNode={
                                        <CloseOutline onClick={closeModal} />
                                    }
                                />
                                <FlexScrollArea height={"100%"} width={"100%"}>
                                    <DataContainer
                                        geospatialSelection={
                                            geospatialSelection
                                        }
                                        channelId={channelId}
                                        multiFilters={multiFilters}
                                        setMultiFilters={setMultiFilters}
                                        options={options}
                                        selectedChip={selectedChip}
                                        databaseId={databaseId}
                                        geographyColumn={geographyColumn}
                                        hasTableId={hasTableId}
                                        toPage={(url) => {
                                            toPage(url);
                                            closeModal();
                                        }}
                                    />
                                </FlexScrollArea>
                            </FlexScrollAreaContainer>
                        )
                    )
                ),
            })
        );
    };

    return (
        <FlexScrollAreaContainer>
            <Grid
                container
                alignItems="center"
                gap={2}
                sx={{
                    padding: 4,
                    borderBottom: 1,
                    borderColor: (theme) => theme.palette.stroke[100],
                }}
            >
                <Grid item>
                    <Typography variant="h6" fontWeight="bold">
                        Map
                    </Typography>
                </Grid>
                <Grid item xs minWidth={0}>
                    <Grid
                        container
                        gap={2}
                        alignItems="center"
                        justifyContent="flex-end"
                        flexWrap="nowrap"
                    >
                        <Grid item minWidth={0}>
                            <HorizontalScroller>
                                <Grid container flexWrap="nowrap" gap={1}>
                                    {pipe(
                                        datasets,
                                        A.filter((ds) =>
                                            isEqual(ds.isSelected, true)
                                        ),
                                        A.map((dataset) => (
                                            <Grid
                                                item
                                                key={dataset.dataSource.id}
                                            >
                                                <DatasetChip
                                                    selectedDataset={dataset}
                                                    clickHandler={
                                                        handleDatasetChipClick
                                                    }
                                                />
                                            </Grid>
                                        ))
                                    )}
                                </Grid>
                            </HorizontalScroller>
                        </Grid>
                        {hasChanges && (
                            <>
                                {snp && (
                                    <Grid item flexShrink={0}>
                                        <ExportContainer
                                            responses={responses}
                                            recentResponse={recentResponse}
                                            viewport={viewport}
                                            multipolygon={polygons}
                                            multiFilters={multiFilters}
                                        />
                                    </Grid>
                                )}

                                <Grid item flexShrink={0}>
                                    <Button
                                        variant="outlined"
                                        color="info"
                                        density="dense"
                                        onClick={() => {
                                            setOpenSaveViewPopper?.(true);
                                        }}
                                    >
                                        Save View
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <FlexScrollArea
                width="100%"
                sx={{
                    flexDirection: "column",
                    breakpoints: {
                        md: {
                            flexDirection: "row",
                        },
                    },
                }}
            >
                <Box
                    sx={{
                        height: "auto",
                        order: 2,
                        breakpoints: {
                            md: {
                                height: "100%",
                                order: 1,
                            },
                        },
                    }}
                >
                    <MapSubMenu
                        options={options}
                        setOptions={setOptions}
                        multiFilters={multiFilters}
                        addRemoveDataset={addRemoveDataset}
                        selectedDataset={selectedDataset}
                        setSelectedDataset={setSelectedDataset}
                        setFunctionType={setFunctionType}
                        setSavedPolygons={setSavedPolygons}
                        activeConsumption={activeConsumption}
                        changeConsumption={changeConsumption}
                        showTriangles={showTriangles}
                        setShowTriangles={setShowTriangles}
                        showPoints={showPoints}
                        setShowPoints={setShowPoints}
                        showFiltered={showFiltered}
                        setShowFiltered={setShowFiltered}
                        clearShapes={() => clearShapes?.()}
                        reFetchSavedAreas={reFetchSavedAreas}
                        setReFetchSavedAreas={setReFetchSavedAreas}
                        selectedAreaId={selectedAreaId}
                        filterItems={filterItems}
                        setFilterItems={setFilterItems}
                        resetAreasFilters={resetAreasFilters}
                        menuOpen={menuOpen}
                        setMenuOpen={setMenuOpen}
                        setMultiFilters={setMultiFilters}
                        datasetShape={datasetShape}
                        setDatasetShape={setDatasetShape}
                        selectedSavedView={selectedSavedView}
                        setSelectedSavedView={setSelectedSavedView}
                        recentResponse={recentResponse}
                        handleSavedPolygons={handleSavedPolygons}
                        savedAreasData={savedAreasData}
                        refetchSavedAreas={refetchSavedAreas}
                        savedViewsData={savedViewsData}
                        loadingSavedViews={loadingSavedViews}
                    />
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        padding: 4,
                        height: "100%",
                        order: 1,
                        breakPoints: {
                            md: {
                                order: 2,
                            },
                        },
                    }}
                >
                    <Box
                        sx={{
                            borderRadius: (theme) => theme.spacing(2),
                            height: "100%",
                            overflow: "hidden",
                        }}
                    >
                        {children({
                            filterItems,
                            setFilterItems,
                            resetAreasFilters,
                            handleSideMenu: (v) => setMenuOpen(v),
                        })}
                    </Box>
                </Box>
            </FlexScrollArea>
        </FlexScrollAreaContainer>
    );
};
