import {
    DbColumnType,
    InputPolygon,
    InputViewBox,
    LogicOperator,
    ReqOptions,
    SchemaRow,
    useFetchSnowflakeDataQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { GridColDef, GridToolbar } from "@biggeo/bg-ui";
import { DataGridContainer, useDataGridOptions } from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import isEqual from "lodash/isEqual";
import lowerCase from "lodash/lowerCase";
import snakeCase from "lodash/snakeCase";
import React, { useState } from "react";
import { match } from "ts-pattern";
import { DggsIndexColumnErrorPage } from "../components/DggsIndexColumnErrorPage";
import { FilterControls } from "./hooks/filters";

const TableContainer = ({
    cols,
    filters,
    geospatialSelection,
    channelId,
    options,
    selectedChip,
    geographyColumn,
}: {
    readonly cols: SchemaRow[];
    readonly filters: FilterControls;
    readonly geospatialSelection:
        | { viewport: InputViewBox }
        | { multipolygon: InputPolygon[] };
    channelId: string;
    options: ReqOptions;
    readonly selectedChip: string;
    readonly geographyColumn: string;
}) => {
    const { filterSearchPaginateProps, dataGridFetchInputProps } =
        useDataGridOptions(50);

    const [date] = useState(Date.now().toString());

    const hasColumn = !pipe(
        cols,
        A.filter((c) =>
            isEqual(
                pipe(c.columnName, lowerCase, snakeCase),
                pipe(geographyColumn, lowerCase, snakeCase)
            )
        ),
        A.isEmpty
    );

    const {
        queryReturn: { data, loading },
    } = useFetchSnowflakeDataQuery({
        variables: {
            filters: {
                filters: {
                    filters:
                        dataGridFetchInputProps.filterObject?.filters || [],
                    databaseId: filters.filters.databaseId,
                    logicOperator:
                        dataGridFetchInputProps.filterObject?.logicOperator ||
                        LogicOperator.and,
                    collection: filters.filters.collection,
                    databaseType: filters.filters.databaseType,
                },
                limitOffset: {
                    limit: 50,
                    offset: 0,
                },
                dateTime: date,
                ...geospatialSelection,
                channelId,
                options,
            },
        },
        skip: !hasColumn,
    });

    const handleReadDocumentation = () => {
        // TODO: Dawood to provide docs
    };

    return (
        <DataGridContainer
            loading={loading}
            columns={[
                ...cols.reduce((acc, c) => {
                    if (acc.some((col) => col.field === c.columnName))
                        return acc;
                    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                    const v: GridColDef<any> = {
                        type: match(c.dataType)
                            .with(DbColumnType.String, () => "string")
                            .with(DbColumnType.Boolean, () => "boolean")
                            .with(DbColumnType.Number, () => "number")
                            .with(DbColumnType.Date, () => "string")
                            .otherwise(() => "string"),
                        field: c.columnName,
                        headerName: c.columnName,
                        filterable: c.columnName !== "ID",
                        valueGetter(params) {
                            if (c.dataType === DbColumnType.Date) {
                                return `${params.value}`;
                            }
                            return params.value;
                        },
                        sortable: false,
                        headerAlign: "left",
                        align: "left",
                        minWidth: match(c.columnName.length * 13)
                            .when(
                                (v) => v > 200,
                                () => 200
                            )
                            .when(
                                (v) => v < 50,
                                () => 50
                            )
                            .otherwise((v) => v),
                    };
                    return acc.concat(v);
                    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                }, [] as GridColDef<any>[]),
            ]}
            rows={
                data?.fetchSnowflakeData.data.map((v, idx) => ({
                    ...v,
                    _id: idx,
                })) || []
            }
            slots={{
                toolbar: GridToolbar,
                ...(!hasColumn && {
                    noRowsOverlay: () => (
                        <DggsIndexColumnErrorPage
                            onClick={handleReadDocumentation}
                        />
                    ),
                }),
            }}
            filterMode="server"
            rowCount={data?.fetchSnowflakeData.count || 0}
            getRowId={(r) => r._id}
            filterSearchPaginateProps={filterSearchPaginateProps}
            title={selectedChip}
            disableSearch
        />
    );
};

export default React.memo(TableContainer);
