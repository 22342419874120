import {
    DatabaseType,
    InputPolygon,
    InputViewBox,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    FlexScrollArea,
    FlexScrollAreaContainer,
    MapLayoutHeader,
} from "@biggeo/bg-ui/lab";

import FilterCriteriaDatasetTableContainer from "./FilterCriteriaDatasetTableContainer";
export type ViewDatasetTableType = {
    readonly dataSourceId: string;
    readonly type: DatabaseType;
    readonly collection: string;
    readonly label?: string;
};
export const ViewDatasetTableContainer = ({
    dataset,
    channelId,
    onClose,
    geospatialSelection,
}: {
    dataset: ViewDatasetTableType;
    channelId: string;
    onClose: () => void;
    geospatialSelection:
        | {
              viewport: InputViewBox;
          }
        | {
              multipolygon: InputPolygon[];
          };
}) => {
    return (
        <FlexScrollAreaContainer>
            <MapLayoutHeader
                vertical={false}
                header={"Table"}
                onClick={() => {
                    onClose();
                }}
            />

            <FlexScrollArea
                flexDirection="column"
                gap={4}
                width={"100%"}
                sx={{
                    padding: 4,
                }}
            >
                <FilterCriteriaDatasetTableContainer
                    filterCriteria={dataset}
                    channelId={channelId}
                    geospatialSelection={geospatialSelection}
                    type="viewTable"
                />
            </FlexScrollArea>
        </FlexScrollAreaContainer>
    );
};
