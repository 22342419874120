import { AutoComplete, svgClasses } from "@biggeo/bg-ui/lab";
import { useEffect, useState } from "react";
import { CustomMapMarker } from "../../../common/components/CustomMapMarker";
import { MapboxIcon, mapboxIconList } from "../../utils/mapbox-icons";

export type DatasetCustomMarkerProps = {
    value?: string;
    onChange: (v?: string) => void;
    color?: string;
};

const DatasetCustomMarker = ({
    value,
    onChange,
    color,
}: DatasetCustomMarkerProps) => {
    const [selectedIcon, setSelectedIcon] = useState<MapboxIcon | undefined>(
        undefined
    );

    useEffect(() => {
        if (value && mapboxIconList.includes(value)) {
            setSelectedIcon(value as MapboxIcon);
        }
    }, [value]);

    return (
        <AutoComplete
            fullWidth
            options={mapboxIconList.map((item) => ({
                label: item,
                startNode: (
                    <CustomMapMarker color={color} icon={item} size="xxs" />
                ),
            }))}
            startNode={
                selectedIcon && (
                    <CustomMapMarker
                        color={color}
                        icon={selectedIcon}
                        size="xxs"
                    />
                )
            }
            optionHeight={36}
            onOptionSelect={(option) => {
                if (option.label) {
                    onChange(option.label);
                    mapboxIconList.includes(option.label) &&
                        setSelectedIcon(option.label as MapboxIcon);
                }
            }}
            value={value}
            placeholder="Search marker"
            disableFloating
            onChange={(_, v) => {
                onChange(v);
            }}
            slotProps={{
                dropdown: {
                    sx: {
                        background: "transparent",
                        padding: 0,
                        marginTop: 2,
                        maxHeight: "300px !important",
                    },
                },
            }}
            sx={{
                [`& .${svgClasses.root}`]: {
                    width: "12px !important",
                    height: "12px !important",
                },
            }}
        />
    );
};

export default DatasetCustomMarker;
