import { ShapeStyle } from "@biggeo/bg-server-lib/datascape-ai";
import { ColorPicker, Stack, colorToHexString } from "@biggeo/bg-ui/lab";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import { AccordionWithDivider } from "../../common/components/AccordionWithDivider";
import { HeatMapClusterColorMapper } from "../../common/components/HeatMapClusterColorMapper";
import FilterCriteriaStylesShape from "../../map/filter-criteria/views/FilterCriteriaStylesShape";
import DatasetCustomMarker from "../../map/mapbox/views/DatasetCustomMarker";
import { mapboxIconList } from "../../map/utils/mapbox-icons";
import { getDatasetShapeColor } from "../../map/utils/style-utils";
import { IDatasetMenu } from "../../map/views/DatasetMenu";

type DatasetCardMapItemProps = Omit<IDatasetMenu, "onBack">;

export const DatasetCardMapItem = ({
    dataset,
    updateHeatMapColor,
    extraOptions,
    updateShape,
    updateColor,
    updateCustomMarker,
}: DatasetCardMapItemProps) => {
    const styles = dataset.mapTemplateDataset?.styles || undefined;
    const { fill, stroke, heatmap } = getDatasetShapeColor(dataset);

    return (
        <Stack gap={2} width="100%">
            <AccordionWithDivider label="Shape">
                <FilterCriteriaStylesShape
                    currentShape={styles?.shape || ShapeStyle.oval}
                    currentCustomMarker={styles?.customMarker || undefined}
                    fill={fill}
                    stroke={stroke}
                    onChange={(shape) => {
                        updateShape({
                            shape,
                            dataset,
                        });
                    }}
                />
            </AccordionWithDivider>
            <AccordionWithDivider label="Fill">
                <ColorPicker
                    initialColor={fill.color}
                    color={fill.color}
                    onChange={(color) => {
                        updateColor({
                            type: "fill",
                            color: colorToHexString(color.rgb),
                            opacity: color.rgb.a,
                            dataset,
                        });
                    }}
                />
            </AccordionWithDivider>
            <AccordionWithDivider label="Stroke">
                <ColorPicker
                    initialColor={stroke?.color}
                    color={stroke?.color}
                    onChange={(color) => {
                        updateColor({
                            type: "stroke",
                            color: colorToHexString(color.rgb),
                            opacity: color.rgb.a,
                            dataset,
                        });
                    }}
                />
            </AccordionWithDivider>
            <AccordionWithDivider label="Custom Marker">
                <DatasetCustomMarker
                    value={styles?.customMarker || undefined}
                    onChange={(v) => {
                        if (v && mapboxIconList.includes(v)) {
                            updateCustomMarker(v, dataset);
                        }
                        if (isEmpty(v) || isUndefined(v)) {
                            updateShape({
                                shape: styles?.shape || ShapeStyle.oval,
                                dataset,
                            });
                        }
                    }}
                    color={fill.color}
                />
            </AccordionWithDivider>
            <AccordionWithDivider label="Data Aggregation">
                <HeatMapClusterColorMapper
                    heatMapValue={heatmap}
                    onClickHeatMapValue={(value) =>
                        updateHeatMapColor(value, dataset)
                    }
                    extraOptions={extraOptions}
                />
            </AccordionWithDivider>
        </Stack>
    );
};
