import {
    FilterObject,
    InputViewBox,
    MapStyleType,
    ReqOptions,
    SavedView,
    useFetchSavedViewByIdQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { MobileDesktopEmulator, Typography } from "@biggeo/bg-ui/lab";
import { Box } from "@biggeo/bg-ui/lab";
import { match } from "@vividtheory/remotedata";
import * as O from "fp-ts/Option";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import compact from "lodash/compact";
import isEqual from "lodash/isEqual";
import uniqBy from "lodash/uniqBy";
import React from "react";
import { useParams } from "react-router";
import { ErrorPage } from "../../../common/components/ErrorPage.tsx";
import { getSavedViewFilters } from "../../filter-criteria/utils/utils.ts";
import { RenderControlsHookReturnType } from "../../hooks/render-data-hooks.ts";
import {
    getInputPolygon,
    getMapFeatures,
    getPolygonsFromSavedAreas,
    isPolygonFeature,
} from "../../utils/utils";
import { getMapStyleFromSprite } from "../../views/MapStyles.tsx";
import SaveViewForm from "../containers/SaveViewForm.tsx";
import { MapContextDataset, MapContextFilter } from "../context/map.ts";
import { SavedPolygonType } from "../hooks/saved-polygon-hooks.ts";

export interface ISaveView {
    readonly openSaveViewPopper?: boolean;
    readonly savedViewId?: number;
    readonly setOpenSaveViewPopper?: (value: boolean) => void;
    readonly bounds?: mapboxgl.LngLatBounds;
    readonly viewport: InputViewBox;
    readonly multiFilters: FilterObject[];

    onCloseDraw: () => void;
    readonly draw: React.MutableRefObject<MapboxDraw | null>;
    readonly map: React.MutableRefObject<mapboxgl.Map | null>;
    readonly isLoaded: boolean;

    readonly filters: MapContextFilter[];
    readonly options: ReqOptions;
    readonly mapStyle: MapStyleType;
    readonly configurationControls: RenderControlsHookReturnType;

    readonly datasets: MapContextDataset[];
    readonly savedPolygons: SavedPolygonType;
}

const SaveView = ({
    openSaveViewPopper,
    setOpenSaveViewPopper,
    draw,
    bounds,
    datasets,
    viewport,
    multiFilters,

    onCloseDraw,
    isLoaded,
    savedViewId,
    filters,
    options,
    mapStyle,
    configurationControls,
    map,
    savedPolygons,
}: ISaveView) => {
    const { remote: savedViewRD } = useFetchSavedViewByIdQuery({
        variables: {
            id: Number(savedViewId),
        },
    });

    const { mapTemplateId } = useParams();

    const savedViewFilters = getSavedViewFilters({ filters, multiFilters });

    if (openSaveViewPopper) {
        onCloseDraw();
    }

    const drawnPolygons = pipe(
        getMapFeatures(draw, isLoaded),
        A.map((feature) =>
            isPolygonFeature(feature) ? getInputPolygon(feature) : undefined
        ),
        compact
    );
    const savedAreaPolygons = uniqBy(
        getPolygonsFromSavedAreas(savedPolygons.polygons),
        (p) => p.properties?.savedAreaId
    );
    const polygons = pipe(drawnPolygons, A.concat(savedAreaPolygons));

    const currentMapStyle =
        map.current && isLoaded ? map.current.getStyle() : undefined;

    const style = isEqual(
        getMapStyleFromSprite(currentMapStyle?.sprite),
        mapStyle
    )
        ? mapStyle
        : undefined;

    const renderForm = (
        svData?: Partial<SavedView> | null,
        isFailure?: boolean
    ) => {
        return (
            <Box>
                <Box
                    sx={{
                        position: "absolute",
                        display: openSaveViewPopper ? "flex" : "none",
                        left: 4,
                        bottom: 4,
                        width: 85.75,
                        backgroundColor: (theme) => theme.palette.surface.main,
                        color: (theme) => theme.palette.background.main,
                        paddingX: 4,
                        paddingY: 3,
                        borderRadius: (theme) => theme.spacing(0.5),
                    }}
                >
                    <Typography variant="body3">
                        Drag viewport to desired starting point. Updated
                        configuration will be saved in this view.
                    </Typography>
                </Box>

                <Box
                    sx={{
                        position: "absolute",
                        display: openSaveViewPopper ? "flex" : "none",
                        right: 4,
                        bottom: 4,
                        ...(isFailure && {
                            width: 100,
                            height: 100,
                        }),
                        border: 1,
                        borderColor: (theme) => theme.palette.stroke[100],
                        borderRadius: 1,
                        boxShadow: (theme) => theme.shadows.allAround,
                    }}
                >
                    {isFailure ? (
                        <MobileDesktopEmulator
                            links={[
                                {
                                    rel: "stylesheet",
                                    href: "https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap",
                                },
                                {
                                    rel: "preconnect",
                                    href: "https://fonts.googleapis.com",
                                },
                                {
                                    rel: "preconnect",
                                    href: "https://fonts.gstatic.com",
                                },
                                {
                                    rel: "icon",
                                    href: "https://assets-global.website-files.com/6345ad9c87035c200bff8d84/65501dcde85a825aba284630_BigGeo%20Fav%20Icon.png",
                                },
                            ]}
                            width="100%"
                            height="100%"
                            disableResize
                        >
                            <ErrorPage />
                        </MobileDesktopEmulator>
                    ) : (
                        <SaveViewForm
                            mapTemplateId={mapTemplateId}
                            datasets={datasets}
                            viewport={viewport}
                            savedView={svData}
                            bounds={bounds}
                            polygons={polygons}
                            setOpenSaveViewPopper={setOpenSaveViewPopper}
                            filters={savedViewFilters}
                            options={options}
                            style={style}
                            configurationControls={configurationControls}
                        />
                    )}
                </Box>
            </Box>
        );
    };

    return pipe(
        savedViewId,
        O.fromPredicate((x) => x !== 0),
        O.fold(
            () => renderForm(null),
            () =>
                match(savedViewRD, {
                    _: () => <></>,
                    Success: (fetchSavedViews) =>
                        renderForm(fetchSavedViews.fetchSavedViewById),
                    Failure: () => renderForm(null, true),
                })
        )
    );
};

export default SaveView;
