import {
    DataSource,
    MapTemplateDataset,
    MapTemplateDatasetExtended,
    UpdateMapTemplateDataset,
    useFetchMapTemplateDatasetsExtendedLazyQuery,
    useUpdateMapTemplateDatasetMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import { GridCellParams, GridColDef, WithLoading } from "@biggeo/bg-ui";
import {
    Box,
    Button,
    ColFilterType,
    DataGridContainer,
    IconAvatar,
    MemoizedField,
    ProgressBar,
    Stack,
    Switch,
    Tooltip,
    Typography,
    useDataGridOptions,
} from "@biggeo/bg-ui/lab";
import { BigGeoLogo, Info, SyncOutline } from "@biggeo/bg-ui/lab/icons";
import clsx from "clsx";
import isEqual from "lodash/isEqual";
import startCase from "lodash/startCase";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { VelocityButton } from "../../../common/components/VelocityButton";
import { hasBgVelocity, isAppRunningOnSF } from "../../../common/redux/hooks";
import { ComputeView } from "../../../data-sets/views/ComputeView";
import { Routes } from "../../../navigation/redux/model";
import { formatNumberWithCommas } from "../../../utils/utils";
import { setDatasetContext } from "../context/context-utils";

const tooltips: {
    computeUsage: string;
    mapUse: string;
    enable: string;
    compute: string;
    defaultColor: string;
} = {
    computeUsage: "The percentage this dataset uses in your available compute",
    mapUse: "Make dataset accessible in the maps interface datasets panel",
    enable: "This determines whether or not this map template has access to the dataset",
    compute:
        "This determines whether or not your data is actively using your available compute",
    defaultColor:
        "The default color for this dataset starts with on blank templates",
};

interface IMapDatasetsConfigGrid {
    readonly route?: "data" | "configuration";
    readonly setMapTemplateDataset: (i: {
        mapTemplateDatasetId: number;
        mapTemplateDataset?: MapTemplateDataset;
        dataSource?: DataSource;
    }) => void;
    readonly isRunningOnSF: boolean;
    readonly mapTemplateId: number;
    readonly totalRows: number;
}

const MapDatasetsConfigGrid = ({
    route,
    setMapTemplateDataset,
    mapTemplateId,
    totalRows,
}: IMapDatasetsConfigGrid) => {
    const { addDatasets, removeDataset } = setDatasetContext();
    const isRunningOnSF = isAppRunningOnSF();
    const [currentRowId, setCurrentRowId] = useState<number | undefined>(
        undefined
    );
    const [mapTemplateDatasets, setMapTemplateDatasets] = useState<
        MapTemplateDatasetExtended[]
    >([]);

    const [currentComputedId, setCurrentComputedId] = useState<
        string | undefined
    >(undefined);

    const {
        executeMutation: updateMapTemplateDataset,
        mutationReturn: [_d, { loading }],
    } = useUpdateMapTemplateDatasetMutation();
    const { filterSearchPaginateProps, dataGridFetchInputProps } =
        useDataGridOptions(20);
    const {
        executeQuery: fetchMapTemplateDatasetsExtended,
        queryReturn: [_, { loading: fetchDatasetsLoading }],
    } = useFetchMapTemplateDatasetsExtendedLazyQuery();

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        fetchMapTemplateDatasetsExtended({
            variables: {
                input: {
                    fkMapTemplateId: mapTemplateId,
                    dataGridFetchInput: dataGridFetchInputProps,
                },
            },
            onCompleted: ({ fetchMapTemplateDatasetsExtended }) => {
                setMapTemplateDatasets(fetchMapTemplateDatasetsExtended);
            },
        });
    }, [filterSearchPaginateProps.filterObject]);

    const update = (input: UpdateMapTemplateDataset) => {
        setCurrentRowId(input.id);

        updateMapTemplateDataset({
            variables: {
                input,
            },
            onCompleted: (data) => {
                setCurrentRowId(undefined);
                setMapTemplateDataset({
                    mapTemplateDatasetId:
                        data.updateMapTemplateDataset.mapTemplateDataset.id,
                    mapTemplateDataset:
                        data.updateMapTemplateDataset.mapTemplateDataset,
                });

                const mapUse =
                    data.updateMapTemplateDataset.mapTemplateDataset.mapUse;

                if (isEqual(mapUse, true)) {
                    addDatasets({
                        dataSource: data.updateMapTemplateDataset.dataSource,
                        mapTemplateDataset:
                            data.updateMapTemplateDataset.mapTemplateDataset,
                        isSelected: false,
                        isVisible: false,
                        isGettingStyled: false,
                        isLegendOpen: false,
                        isTableViewed: false,
                    });
                }

                if (isEqual(mapUse, false)) {
                    removeDataset({
                        dataSourceId:
                            data.updateMapTemplateDataset.dataSource.id,
                        mapTemplateDatasetId:
                            data.updateMapTemplateDataset.mapTemplateDataset.id,
                    });
                }

                setMapTemplateDatasets(
                    mapTemplateDatasets.map((mapTemplateDataset) =>
                        mapTemplateDataset.mapTemplateDataset.id ===
                        data.updateMapTemplateDataset.mapTemplateDataset.id
                            ? data.updateMapTemplateDataset
                            : mapTemplateDataset
                    )
                );
            },
        });
    };

    const columns: GridColDef<MapTemplateDatasetExtended>[] = [
        {
            field: "label",
            headerName: "Name",
            flex: 1,
            minWidth: 378,
            sortable: false,
            resizable: false,
            type: ColFilterType.string,
            filterable: true,
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            cellClassName: (params: GridCellParams<any, number>) => {
                if (params.value == null) {
                    return "";
                }

                return clsx("super-app", {
                    negative: params.value < 0,
                    positive: params.value > 0,
                });
            },
            renderCell: (params) => (
                <MemoizedField
                    disable={Boolean(!params.row.dataSource?.compute)}
                    title={
                        params.row.dataSource?.label ||
                        params.row.dataSource?.tableName ||
                        ""
                    }
                    subTitle={params.row.dataSource?.description || undefined}
                    typographyProps={{
                        truncate: true,
                    }}
                />
            ),
        },
        {
            field: "defaultColor",
            headerName: "Default Color",
            headerAlign: "center",
            flex: 1,
            minWidth: 176,
            sortable: false,
            resizable: false,
            type: ColFilterType.string,
            filterable: true,

            renderHeader: (params) => (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="body3" fontWeight="semibold">
                        {startCase(params.colDef.headerName)}
                    </Typography>
                    <Tooltip title={tooltips.defaultColor} sx={{ width: 45 }}>
                        <span>
                            <Info color="primary" size="xs" />
                        </span>
                    </Tooltip>
                </Stack>
            ),
            renderCell: (params) => {
                return (
                    <MemoizedField
                        disable={Boolean(!params.row.dataSource?.compute)}
                    >
                        <Box
                            sx={{
                                backgroundColor:
                                    params.row.mapTemplateDataset?.color ||
                                    params.row.dataSource?.color ||
                                    "#ffffff",
                                width: 6,
                                height: 6,
                            }}
                        />
                    </MemoizedField>
                );
            },
        },
        {
            field: "velocity",
            headerName: "Velocity",
            minWidth: 125,
            sortable: false,
            headerAlign: "center",
            type: ColFilterType.boolean,
            renderCell: (params) => (
                <MemoizedField>
                    <VelocityButton
                        hasVelocity={
                            !!params.row.dataSource.fkMarketplaceDatasetId ||
                            hasBgVelocity()
                        }
                    />
                </MemoizedField>
            ),
            renderHeader: (params) => (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="body3" fontWeight="semibold">
                        {startCase(params.field)}
                    </Typography>
                    <Tooltip
                        title="Fast geospatial querying engine that processes data up to 100x faster."
                        sx={{ maxWidth: 45 }}
                        bodyNode={
                            <Link
                                to={Routes.bigGeoProductsVelocity}
                                target="_blank"
                            >
                                <Button variant="outlined" fullWidth>
                                    Learn More
                                </Button>
                            </Link>
                        }
                    >
                        <span>
                            <Info color="primary" size="xs" />
                        </span>
                    </Tooltip>
                </Stack>
            ),
        },
        {
            field: "src",
            headerName: "Src",
            minWidth: 150,
            sortable: false,
            headerAlign: "center",
            resizable: false,
            type: ColFilterType.string,
            renderCell: (params) => (
                <MemoizedField
                    disable={Boolean(!params.row.dataSource?.compute)}
                >
                    <IconAvatar color="primary" size="xs">
                        <BigGeoLogo />
                    </IconAvatar>
                </MemoizedField>
            ),
        },
        {
            field: "size",
            headerName: "Size",
            minWidth: 150,
            sortable: false,
            headerAlign: "left",
            type: ColFilterType.number,
            filterable: true,
            renderCell: (params) => (
                <MemoizedField
                    disable={Boolean(!params.row.dataSource?.compute)}
                    title={formatNumberWithCommas(
                        params.row.dataSource?.size || 0
                    )}
                    sx={{ justifyContent: "flex-start", textAlign: "left" }}
                />
            ),
        },
        {
            field: "compute",
            headerName: "compute",
            minWidth: 110,
            sortable: false,
            type: ColFilterType.boolean,
            renderHeader: (params) => (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="body3" fontWeight="semibold">
                        {startCase(params.field)}
                    </Typography>
                    <Tooltip title={tooltips.compute}>
                        <span>
                            <Info color="primary" size="xs" />
                        </span>
                    </Tooltip>
                </Stack>
            ),
            renderCell: (params) => {
                return (
                    <ComputeView
                        collectionName={params.row.dataSource.collectionName}
                        id={params.row.dataSource.id}
                        currentComputedId={currentComputedId}
                        setCurrentComputedId={setCurrentComputedId}
                    />
                );
            },
        },
        {
            field: "computeUsage",
            headerName: "Compute Usage",
            minWidth: 160,
            sortable: false,
            headerAlign: "left",
            type: ColFilterType.boolean,
            renderHeader: (params) => (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="body3" fontWeight="semibold">
                        {startCase(params.colDef.headerName)}
                    </Typography>
                    <Tooltip title={tooltips.computeUsage} sx={{ width: 45 }}>
                        <span>
                            <Info color="primary" size="xs" />
                        </span>
                    </Tooltip>
                </Stack>
            ),
            renderCell: (params) => {
                return (
                    <MemoizedField
                        disable={Boolean(!params.row.dataSource?.compute)}
                    >
                        <Stack sx={{ width: "100%" }} gap={2}>
                            <Typography variant="body4" fontWeight="semibold">
                                {`${params.row.dataSource?.progress || 0}%`}
                            </Typography>
                            <ProgressBar
                                value={10}
                                sx={{
                                    height: (theme) => theme.spacing(1.5),
                                    borderRadius: 4,
                                    backgroundColor: (theme) =>
                                        theme.palette.disabled.main,
                                }}
                            />
                        </Stack>
                    </MemoizedField>
                );
            },
        },
        // {
        //     field: "enable",
        //     sortable: false,
        //     minWidth: 150,
        //     headerName: "Enable",
        //     renderHeader: (params) => (
        //         <Stack flexDirection="row" alignItems="center" gap={1}>
        //             <Typography variant="body3" fontWeight="semibold">
        //                 {startCase(params.colDef.headerName)}
        //             </Typography>
        //             <Tooltip title={tooltips.enable}>
        //                 <span>
        //                     <Info color="primary" size="xs" />
        //                 </span>
        //             </Tooltip>
        //         </Stack>
        //     ),
        //     renderCell: (params) => {
        //         return (
        //             <MemoizedField
        //                 disable={Boolean(!params.row.dataSource?.compute)}
        //             >
        //                 <WithLoading
        //                     loading={
        //                         isEqual(
        //                             currentRowId,
        //                             params.row.mapTemplateDataset?.id
        //                         )
        //                             ? loading
        //                             : false
        //                     }
        //                 >
        //                     <Switch
        //                         size="small"
        //                         color="surface"
        //                         switched={params.row.mapTemplateDataset?.enable}
        //                         onSwitchChange={() => {
        //                             const enable =
        //                                 !params.row.mapTemplateDataset?.enable;

        //                             update(
        //                                 {
        //                                     id:
        //                                         params.row.mapTemplateDataset
        //                                             ?.id || 0,
        //                                     enable,
        //                                     mapUse: isEqual(enable, false)
        //                                         ? false
        //                                         : undefined,
        //                                 }
        //                                 // params.row.mapTemplateDataset.id
        //                             );
        //                         }}
        //                     />
        //                 </WithLoading>
        //             </MemoizedField>
        //         );
        //     },
        // },
        {
            field: "manage",
            sortable: false,
            headerName: "",
            minWidth: 110,
            renderCell: (params) => {
                const refresh = !params.row.dataSource?.isConnected;
                const currentUrl = window.location.href;
                const toPage = useNavigate();
                return (
                    <Stack sx={{ width: "100%" }}>
                        {refresh ? (
                            <Button
                                fullWidth
                                variant="outlined"
                                density="dense"
                                endNode={<SyncOutline size="xs" />}
                                onClick={() => {}}
                            >
                                Refresh
                            </Button>
                        ) : (
                            <Button
                                fullWidth
                                variant="outlined"
                                density="dense"
                                onClick={() =>
                                    toPage(
                                        `${Routes.dataManage}/${params.row.dataSource?.id}`,
                                        {
                                            state: {
                                                returnUrl: currentUrl,
                                            },
                                        }
                                    )
                                }
                            >
                                Manage
                            </Button>
                        )}
                    </Stack>
                );
            },
        },
        {
            field: "mapUse",
            headerName: "Map use",
            minWidth: 115,
            sortable: false,
            type: ColFilterType.boolean,
            renderHeader: (params) => (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="body3" fontWeight="semibold">
                        {startCase(params.colDef.headerName)}
                    </Typography>
                    <Tooltip title={tooltips.mapUse} sx={{ width: 45 }}>
                        <span>
                            <Info color="primary" size="xs" />
                        </span>
                    </Tooltip>
                </Stack>
            ),
            renderCell: (params) => (
                <MemoizedField
                    disable={Boolean(!params.row.dataSource?.compute)}
                >
                    <WithLoading
                        loading={
                            isEqual(
                                currentRowId,
                                params.row.mapTemplateDataset?.id
                            )
                                ? loading
                                : false
                        }
                    >
                        <Switch
                            size="small"
                            color="surface"
                            switched={params.row.mapTemplateDataset?.mapUse}
                            onSwitchChange={() => {
                                setCurrentRowId(
                                    params.row.mapTemplateDataset?.id
                                );

                                update({
                                    id: params.row.mapTemplateDataset?.id || 0,
                                    mapUse:
                                        !params.row.mapTemplateDataset?.mapUse,
                                });
                            }}
                        />
                    </WithLoading>
                </MemoizedField>
            ),
        },
    ];

    return (
        <DataGridContainer
            getRowId={(row) => row.mapTemplateDataset?.id || 0}
            pinnedColumns={{ right: ["mapUse", "manage"] }}
            columnVisibilityModel={{
                mapUse: route === "configuration",
                enable: route === "data",
                manage: route === "data" && isRunningOnSF,
                compute: route === "data" && isRunningOnSF,
                computeUsage: false,
            }}
            columns={columns}
            rows={mapTemplateDatasets}
            rowCount={totalRows}
            loading={loading || fetchDatasetsLoading}
            title={route === "configuration" ? "Datasets" : "Data source"}
            filterSearchPaginateProps={filterSearchPaginateProps}
            disableColumnResize
        />
    );
};

export default MapDatasetsConfigGrid;
