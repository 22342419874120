import { FilterObject, ReqOptions } from "@biggeo/bg-server-lib/datascape-ai";
import { InputViewBox } from "@biggeo/bg-server-lib/datascape-ai";
import debounce from "lodash/debounce";
import { useEffect } from "react";
import { FunctionType, getBounds, matchFunctionType } from "../../utils/utils";

export const useViewport = ({
    functionType,
    map,
    multiFilters,
    handleViewportChange,
    viewport,
    options,
    isSavedViewPage,
}: {
    functionType: FunctionType;
    map: React.MutableRefObject<mapboxgl.Map | null>;
    multiFilters: FilterObject[];
    handleViewportChange: ({ viewport }: { viewport: InputViewBox }) => void;
    viewport: InputViewBox;
    options: ReqOptions;
    isSavedViewPage: boolean;
}) => {
    const onViewportChange = (viewport: InputViewBox) => {
        if (!isSavedViewPage) {
            matchFunctionType(functionType, {
                viewport: () => {
                    handleViewportChange({ viewport });
                },
            });
        }
    };

    const viewportFunction = () => {
        const bounds = map.current?.getBounds();

        if (bounds) {
            const fittedBounds = getBounds(bounds);

            onViewportChange(fittedBounds);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!isSavedViewPage) {
            handleMove();
        }
    }, [multiFilters, isSavedViewPage]);

    const handleMove = debounce(() => {
        matchFunctionType(functionType, {
            viewport: () => viewportFunction(),
        });
    }, 250);
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const current = map.current;

        if (current && !isSavedViewPage) {
            current.on("moveend", handleMove);
            current.on("movestart", () => {
                handleMove.cancel();
            });
        }
        return () => {
            if (current) {
                current.off("moveend", handleMove);
                current.off("movestart", () => {
                    handleMove.cancel();
                });
            }
        };
    }, [map.current, functionType, options, handleMove, isSavedViewPage]);

    const onStyleLoad = () => {
        viewportFunction();
    };

    return { onStyleLoad, viewport, bounds: map.current?.getBounds() };
};
