import {
    FilterItem,
    FilterObject,
    FilterObjectType,
    LogicOperator,
    MapTemplateDatasetExtended,
} from "@biggeo/bg-server-lib/datascape-ai";
import { PartialBy } from "@biggeo/bg-utils";
import { withDefault } from "@vividtheory/remotedata";
import { useState } from "react";
import { usePreviewDatasets } from "../../database-meta-data/redux/hooks";
import { useMap } from "../mapbox/context";

export type FilterControls = {
    filters: FilterObject;
    setFilters: (f: FilterObject) => FilterObject;
};

export const useFilters = (): {
    multiFilters: FilterObject[];
    addRemoveDataset: (v: string) => void;
    setMultiFilters: (mf: FilterObject[]) => void;
    addMultipleDatasets: (v: readonly FilterObjectType[]) => void;
} => {
    const { datasets: mapTemplateDatasets } = useMap();

    const _preview = withDefault(
        { dataSources: [], total: 0, snowflakeData: [] },
        usePreviewDatasets()
    );

    const datasets: PartialBy<
        MapTemplateDatasetExtended,
        "mapTemplateDataset"
    >[] = [
        ...mapTemplateDatasets,
        ..._preview.dataSources.map((c) => ({ dataSource: c })),
    ];

    const [multiFilters, setMultiFilters] = useState<FilterObject[]>([]);

    const addMultipleDatasets = (databaseIds: readonly FilterObjectType[]) => {
        const database = datasets.filter((c) =>
            databaseIds.some((d) => d.databaseId === c.dataSource.id)
        );

        if (database.length === 0) return;

        setMultiFilters([
            ...multiFilters.filter(
                (c) => !databaseIds.some((d) => c.databaseId === d.databaseId)
            ),
            ...database.map((c) => {
                const find = databaseIds.find(
                    (d) => d.databaseId === c.dataSource.id
                );
                const mf = multiFilters.find(
                    (m) => m.databaseId === c.dataSource.id
                );
                if (!find)
                    return {
                        isPreview: c.dataSource.isPreview,
                        databaseId: c.dataSource.id,
                        filters: [],
                        logicOperator: LogicOperator.and,
                        collection: c.dataSource.collectionName,
                        databaseType: c.dataSource.type,
                    };

                const updatedFilters = [
                    ...(find.filters?.map(
                        (d): FilterItem => ({
                            column: d.column,
                            data: {
                                dateData: d.data.dateData,
                                numberData: d.data.numberData,
                                stringData: d.data.stringData,
                                booleanData: d.data.booleanData,
                            },
                            operator: d.operator,
                            type: d.type,
                        })
                    ) || []),
                    ...(mf?.filters || []),
                ];
                return {
                    isPreview: c.dataSource.isPreview,
                    databaseId: c.dataSource.id,
                    filters: updatedFilters.filter(
                        (filter, index) =>
                            index ===
                            updatedFilters.findIndex(
                                (other) => filter.column === other.column
                            )
                    ),
                    logicOperator: LogicOperator.and,
                    collection: c.dataSource.collectionName,
                    databaseType: c.dataSource.type,
                };
            }),
        ]);
    };

    const addRemoveDataset = (databaseId: string) => {
        const database = datasets.find((c) => c.dataSource.id === databaseId);
        if (!database) {
            return;
        }
        const data = multiFilters.find((f) => f.databaseId === databaseId);
        if (data) {
            setMultiFilters(
                multiFilters.filter((f) => f.databaseId !== databaseId)
            );
        } else {
            setMultiFilters([
                ...multiFilters,
                {
                    isPreview: database.dataSource.isPreview,
                    databaseId: database.dataSource.id,
                    filters: [],
                    logicOperator: LogicOperator.and,
                    collection: database.dataSource.collectionName,
                    databaseType: database.dataSource.type,
                },
            ]);
        }
    };

    return {
        multiFilters,
        addRemoveDataset,
        setMultiFilters,
        addMultipleDatasets,
    };
};
