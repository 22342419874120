import { CreateSavedArea } from "@biggeo/bg-server-lib/datascape-ai";
import { DraggablePopper, WithLoading } from "@biggeo/bg-ui";
import {
    Box,
    Button,
    Dropdown,
    MenuItem,
    MultilineTextField,
    OverlayWrapper,
    Stack,
    TextField,
    Tooltip,
    Typography,
    buttonClasses,
} from "@biggeo/bg-ui/lab";
import {
    ArrowSelectorToolOutline,
    CloseOutline,
    DeleteOutline,
    InsertChartOutline,
} from "@biggeo/bg-ui/lab/icons";
import { useState } from "react";
import ScreenshotRegionOutline from "../../icons/ScreenshotRegionOutline";
import { MapModeExpandIcon } from "./DrawMode";

interface ISelectMode {
    readonly isSelectMode: boolean;
    readonly selectMode: (mode: boolean) => void;
    readonly hasSelectedShapes: boolean;
    readonly draggablePopper: HTMLElement | null;
    readonly setDraggablePopper: React.Dispatch<
        React.SetStateAction<HTMLElement | null>
    >;
    readonly loading: boolean;
    readonly saveArea: (
        input: Pick<
            CreateSavedArea,
            "name" | "isEnabled" | "description" | "mapUse"
        >
    ) => void;
    readonly deleteArea: () => void;
    readonly onClose: () => void;
}

const SelectMode = ({
    isSelectMode,
    selectMode,
    hasSelectedShapes,
    draggablePopper,
    setDraggablePopper,
    loading,
    saveArea,
    deleteArea,
    onClose,
}: ISelectMode) => {
    const [open, setOpen] = useState(false);

    const [name, setName] = useState<string | undefined>(undefined);
    const [description, setDescription] = useState<string | undefined>(
        undefined
    );

    const handleDraggablePopper = (e?: React.MouseEvent<HTMLElement>) => {
        setDraggablePopper(e ? e.currentTarget : null);
    };

    return (
        <>
            <OverlayWrapper
                placement={{ horizontal: "right", vertical: "top" }}
                placementOffset={{ horizontal: -2, vertical: -1 }}
                content={
                    isSelectMode && (
                        <CloseOutline
                            size="xxs"
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpen(false);
                                onClose();
                                selectMode(false);
                            }}
                            sx={{
                                cursor: "pointer",
                                padding: 0.5,
                                backgroundColor: (theme) =>
                                    theme.palette.surface.main,
                                color: (theme) => theme.palette.surface.onMain,
                                borderRadius: (theme) => theme.radius.xs5,
                            }}
                        />
                    )
                }
            >
                {hasSelectedShapes ? (
                    <Dropdown
                        open={open}
                        onOpenChange={(o) => setOpen(o)}
                        placement="bottom-end"
                        onClick={() => {
                            selectMode(true);
                            onClose();
                        }}
                        slotProps={{
                            button: {
                                disableHoverEffect: true,
                                density: "none",
                                sx: {
                                    [`&.${buttonClasses.outlined}`]: {
                                        backgroundColor: (theme) =>
                                            theme.palette.background.main,
                                        border: 1,
                                        borderColor: (theme) =>
                                            theme.palette.stroke[100],
                                        padding: 1,
                                        borderRadius: (theme) =>
                                            theme.radius.default,
                                    },
                                },
                                endNode: <MapModeExpandIcon open={open} />,
                            },
                        }}
                        label={
                            <Box
                                sx={{
                                    backgroundColor: isSelectMode
                                        ? (theme) => theme.palette.stroke[100]
                                        : undefined,
                                    padding: 1.5,
                                    borderRadius: (theme) =>
                                        theme.radius.default,
                                }}
                            >
                                <ArrowSelectorToolOutline size="sm" />
                            </Box>
                        }
                        content={
                            <>
                                <MenuItem
                                    label="Save area"
                                    density="dense"
                                    startNode={<ScreenshotRegionOutline />}
                                    onClick={(e) => {
                                        handleDraggablePopper(e);
                                    }}
                                />
                                <MenuItem
                                    label="Area data"
                                    density="dense"
                                    disabled
                                    startNode={<InsertChartOutline />}
                                />
                                <MenuItem
                                    label="Delete"
                                    color={"error"}
                                    density="dense"
                                    startNode={
                                        <DeleteOutline color={"error"} />
                                    }
                                    onClick={() => {
                                        deleteArea();
                                    }}
                                />
                            </>
                        }
                    />
                ) : (
                    <Tooltip title={"Select shapes"}>
                        <Button
                            onClick={() => {
                                selectMode(true);
                                onClose();
                            }}
                            variant="outlined"
                            density="dense"
                            disableFocusEffect
                            sx={{
                                [`&.${buttonClasses.outlined}`]: {
                                    backgroundColor: (theme) =>
                                        theme.palette.background.main,
                                    border: 1,
                                    borderColor: (theme) =>
                                        theme.palette.stroke[100],
                                    padding: 2.4,
                                    borderRadius: (theme) =>
                                        theme.radius.default,
                                },
                            }}
                        >
                            <ArrowSelectorToolOutline />
                        </Button>
                    </Tooltip>
                )}
            </OverlayWrapper>

            <DraggablePopper
                open={!!draggablePopper}
                anchorEl={draggablePopper}
                onClose={() => handleDraggablePopper()}
                sx={{
                    minHeight: undefined,
                }}
            >
                <Stack
                    gap={3}
                    width="100%"
                    sx={{
                        padding: 4,
                    }}
                >
                    <Stack gap={1}>
                        <Typography variant="title2" fontWeight="bold">
                            Save Area
                        </Typography>
                        <Typography variant="title2">
                            Enter the details below to save
                        </Typography>
                    </Stack>
                    <TextField
                        fullWidth
                        label={"Area name"}
                        placeholder="Enter name"
                        required
                        value={name}
                        onChange={(e) => setName(e?.currentTarget.value)}
                    />
                    <MultilineTextField
                        fullWidth
                        label={"Description"}
                        placeholder="Enter description"
                        optional
                        value={description}
                        onChange={(e) => setDescription(e?.currentTarget.value)}
                    />
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Stack sx={{ flexDirection: "row" }} gap={2}>
                            <Button
                                color="surface"
                                variant={"outlined"}
                                onClick={() => {
                                    handleDraggablePopper();
                                    selectMode(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <WithLoading loading={loading} text="Save">
                                <Button
                                    color="surface"
                                    disabled={!name}
                                    variant={"filled"}
                                    onClick={() => {
                                        if (name) {
                                            saveArea({
                                                name,
                                                description,
                                                isEnabled: true,
                                                mapUse: true,
                                            });
                                            handleDraggablePopper();
                                            selectMode(false);
                                        }
                                    }}
                                >
                                    Save Area
                                </Button>
                            </WithLoading>
                        </Stack>
                    </Box>
                </Stack>
            </DraggablePopper>
        </>
    );
};

export default SelectMode;
