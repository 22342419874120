import type { SavedArea } from "@biggeo/bg-server-lib/datascape-ai";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import type { GeoJSONSource } from "mapbox-gl";
import { useEffect } from "react";
import { snapToView } from "../../../utils/utils";
import type { InputPolygonWithId } from "../../hooks/pure-data-string-hook";
import {
    SavedPolygonSource,
    getInputPolygon,
    getMapFeatures,
    getOutsideArea,
    getPolygonsFromSavedAreas,
    getPolygonsThatOverlapOtherPolygons,
} from "../../utils/utils";
import { DEFAULT_SHAPE_COLOR, DEFAULT_SHAPE_OPACITY } from "./style-hooks";

export const OUTSIDE_AREA_LAYER_ID = "background-mask";
export const SAVED_AREAS_ADDITIONAL_OUTLINE_LAYER_ID =
    "saved-polygons-additional-outline";

export type SavedPolygonType = {
    source: SavedPolygonSource;
    polygons: readonly SavedArea[];
    isConflict?: boolean;
};

export type SavedPolygonsHookProps = {
    map: React.MutableRefObject<mapboxgl.Map | null>;
    savedPolygons: SavedPolygonType;
    handleSavedPolygons: (p: InputPolygonWithId[]) => void;
    draw: React.MutableRefObject<MapboxDraw | null>;
    isLoaded: boolean;
    style?: mapboxgl.Style;
};

export const useSavedPolygon = ({
    map,
    savedPolygons,
    handleSavedPolygons,
    draw,
    isLoaded,
    style,
}: SavedPolygonsHookProps) => {
    const drawnPolygons = pipe(
        getMapFeatures(draw, isLoaded),
        A.map(getInputPolygon)
    );

    const onSavedAreasChange = (savedPolygons: SavedPolygonType) => {
        const current = map.current;

        if (current && isLoaded) {
            const saved = savedPolygons.polygons;

            const source = map.current?.getSource(
                "saved-polygons"
            ) as GeoJSONSource;

            const maskSource = map.current?.getSource(
                "saved-polygons-background"
            ) as GeoJSONSource;

            if (!isEmpty(saved)) {
                const { outsideArea, savedAreaPolygons } =
                    getOutsideArea(saved);

                const savedPolygonsCollection = {
                    type: MapboxDraw.constants.geojsonTypes.FEATURE_COLLECTION,
                    features: savedAreaPolygons,
                };

                if (source) {
                    source.setData(savedPolygonsCollection);
                }

                if (maskSource) {
                    maskSource.setData(outsideArea);
                }

                const selectedPolygons = getPolygonsFromSavedAreas(saved);

                // Only show datasets for the saved areas that don't have an
                // inner drawn polygon.
                const polygonsWithInners = getPolygonsThatOverlapOtherPolygons(
                    drawnPolygons,
                    selectedPolygons
                );

                if (!isEmpty(polygonsWithInners)) {
                    const polygonsWithoutInnerPolygons = pipe(
                        selectedPolygons,
                        A.filter((p) => !polygonsWithInners.includes(p))
                    );

                    handleSavedPolygons(
                        pipe(
                            polygonsWithoutInnerPolygons,
                            A.concat(drawnPolygons)
                        )
                    );
                } else {
                    handleSavedPolygons(selectedPolygons);
                }

                snapToView({
                    polygons: [...selectedPolygons, ...drawnPolygons],
                    map,
                });
            } else {
                const defaultData: GeoJSON.FeatureCollection<
                    GeoJSON.Geometry,
                    GeoJSON.GeoJsonProperties
                > = {
                    type: "FeatureCollection",
                    features: [],
                };

                if (source) {
                    source.setData(defaultData);
                }

                if (maskSource) {
                    maskSource.setData(defaultData);
                }
            }
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (isEqual(savedPolygons.source, SavedPolygonSource.savedArea)) {
            onSavedAreasChange(savedPolygons);
        }
    }, [savedPolygons, drawnPolygons.length, isLoaded, style?.sprite]);

    const onStyleLoad = (map: mapboxgl.Map) => {
        map.addSource("saved-polygons", {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [],
            },
        });

        map.addSource("saved-polygons-background", {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [],
            },
        });

        map.addLayer({
            id: OUTSIDE_AREA_LAYER_ID,
            type: "fill",
            source: "saved-polygons-background",
            interactive: false,
            paint: {
                "fill-color": "#D0D5DD",
                "fill-opacity": 0.5,
            },
        });

        map.addLayer({
            id: "saved-polygons-outline",
            type: "line",
            source: "saved-polygons",
            interactive: false,
            paint: {
                "line-color": [
                    "coalesce",
                    ["get", "stroke-color"],
                    DEFAULT_SHAPE_COLOR,
                ],
                "line-width": ["coalesce", ["get", "stroke-width"], 2],
                "line-opacity": ["coalesce", ["get", "stroke-opacity"], 1],
            },
        });

        // An additional invisible outline to stop the `not-allowed`
        // cursor from showing once it gets close to a saved area.
        map.addLayer({
            id: SAVED_AREAS_ADDITIONAL_OUTLINE_LAYER_ID,
            type: "line",
            source: "saved-polygons",
            interactive: false,
            paint: {
                "line-color": DEFAULT_SHAPE_COLOR,
                "line-width": 10,
                "line-opacity": 0,
            },
        });

        map.addLayer({
            id: "saved-polygons-fill",
            type: "fill",
            source: "saved-polygons",
            interactive: false,
            paint: {
                "fill-color": [
                    "coalesce",
                    ["get", "fill-color"],
                    DEFAULT_SHAPE_COLOR,
                ],
                "fill-opacity": [
                    "coalesce",
                    ["get", "fill-opacity"],
                    DEFAULT_SHAPE_OPACITY,
                ],
            },
        });
    };

    return { onStyleLoad };
};
