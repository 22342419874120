import { useExportDatasetsMutation } from "@biggeo/bg-server-lib/datascape-ai";
import {
    BreadcrumbsButton,
    BreadcrumbsGroup,
    Severity,
} from "@biggeo/bg-ui/lab";
import { CenteredNestedLayoutWithHeader } from "@biggeo/bg-ui/lab/layouts";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { MapContextFilter, useMap } from "../../map/mapbox/context";
import { toasterActions } from "../../toaster/containers/redux/model";
import { getFiltersForExport } from "../../utils/utils";
import { ExportToSFContainer } from "../containers/ExportToSFContainer";

interface IExportToSFPage {
    readonly setIsExporting?: (isExporting: boolean) => void;
}

export const ExportToSFPage = ({ setIsExporting }: IExportToSFPage) => {
    const dispatch = useDispatch();
    const {
        executeMutation: exportDataset,
        mutationReturn: [_, { loading: exportDatasetLoading }],
    } = useExportDatasetsMutation();

    const { filters: mapContextFilters, datasets } = useMap();

    const completeDatasetExport = ({
        collection,
        tableName,
    }: { collection: string; tableName: string | null | undefined }) => {
        setIsExporting?.(false);
        dispatch(
            toasterActions.openToast({
                open: true,
                title: `Successfully exported ${collection} to ${tableName}`,
                autoHideDuration: 5000,
                severity: Severity.success,
            })
        );
    };
    const failedDatasetExport = (errorMessage: string) => {
        dispatch(
            toasterActions.openToast({
                open: true,
                title: `Failed to export dataset: ${errorMessage}`,
                autoHideDuration: 5000,
                severity: Severity.error,
            })
        );
    };

    const onSubmit = (datasetsToExport: string[]) => {
        const allSelectedDatasets = pipe(
            datasets,
            A.filter((item) => item.isSelected)
        );

        const allVisibleDatasetFilters: MapContextFilter[] = pipe(
            mapContextFilters,
            A.filter(
                (filter) =>
                    filter.visible &&
                    pipe(
                        filter.filterCriteria,
                        A.some((item) =>
                            datasetsToExport.includes(item.dataSourceId)
                        )
                    )
            )
        );
        pipe(
            allVisibleDatasetFilters,
            O.fromPredicate((x) => !isEmpty(x)),
            O.foldW(
                () => {
                    // If no filters applied
                    pipe(
                        getFiltersForExport({
                            datasets: allSelectedDatasets,
                            filters: pipe(
                                allSelectedDatasets,
                                A.map(
                                    (item) =>
                                        ({
                                            id: item.dataSource.id,
                                            visible: true,
                                            selected: false,
                                            disabled: true,
                                        }) as MapContextFilter
                                )
                            ),
                        }),
                        A.map((item) => {
                            exportDataset({
                                variables: {
                                    input: item,
                                },
                                onCompleted: ({ exportDatasets }) => {
                                    completeDatasetExport({
                                        collection: item.collection,
                                        tableName: exportDatasets.tableName,
                                    });
                                },
                                onError: (error) => {
                                    failedDatasetExport(error.message);
                                },
                            });
                        })
                    );
                },
                (filters) =>
                    pipe(
                        getFiltersForExport({
                            datasets,
                            filters,
                        }),
                        A.map((item) => {
                            exportDataset({
                                variables: {
                                    input: item,
                                },
                                onCompleted: ({ exportDatasets }) => {
                                    completeDatasetExport({
                                        collection: item.collection,
                                        tableName: exportDatasets.tableName,
                                    });
                                },
                                onError: (error) => {
                                    failedDatasetExport(error.message);
                                },
                            });
                        })
                    )
            )
        );
    };

    return (
        <CenteredNestedLayoutWithHeader
            title={
                <BreadcrumbsGroup value={"export"}>
                    <BreadcrumbsButton
                        value="marketplace"
                        onClick={() => setIsExporting?.(false)}
                    >
                        Marketplace
                    </BreadcrumbsButton>
                    <BreadcrumbsButton value={"export"} hideSeparator>
                        Export datasets
                    </BreadcrumbsButton>
                </BreadcrumbsGroup>
            }
        >
            <ExportToSFContainer
                onSubmit={onSubmit}
                loading={exportDatasetLoading}
            />
        </CenteredNestedLayoutWithHeader>
    );
};
