import { DrawCreateEvent, DrawRenderEvent } from "@mapbox/mapbox-gl-draw";
import { SavedPolygonType } from "./saved-polygon-hooks";
import { CONFLICT_SHAPE_COLOR, CustomShapeSource } from "./style-hooks";

import { Theme, useMediaQuery } from "@biggeo/bg-ui";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import isNull from "lodash/isNull";
import isString from "lodash/isString";
import last from "lodash/last";
import { GeoJSONSource } from "mapbox-gl";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { modalActions } from "../../../modal/redux/model";
import { toasterActions } from "../../../toaster/containers/redux/model";
import { drag, removeCustomLayers, resize } from "../../utils/style-utils";
import {
    SavedPolygonSource,
    getConflictAreas,
    getMapFeatures,
    getPolygonTooltipPosition,
    isPolygon,
    setDrawFeature,
} from "../../utils/utils";
import { MapAction } from "../context/map";
import { isLayerPresent } from "../utils/data-layers-utils";
import ConflictAreasModal from "../views/ConflictAreasModal";
import {
    MAP_TOOLTIP_HEIGHT,
    MAP_TOOLTIP_WIDTH,
    MapPopupType,
} from "../views/MapPromptPopup";

export type ConflictAreasProps = {
    map: React.MutableRefObject<mapboxgl.Map | null>;
    savedPolygons: SavedPolygonType;
    draw: React.MutableRefObject<MapboxDraw | null>;
    isLoaded: boolean;
    isSelectMode: boolean;
    handleSelectedShapes: (
        i:
            | GeoJSON.FeatureCollection<
                  GeoJSON.Geometry,
                  GeoJSON.GeoJsonProperties
              >
            | GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
    ) => void;
    deleteShape: (id: string) => void;
    handleSavedAreas: (i: { unselect: boolean }) => void;
    mapDispatch?: React.Dispatch<MapAction>;
};

export const useConflictAreas = ({
    map,
    savedPolygons,
    draw,
    isLoaded,
    isSelectMode,
    handleSelectedShapes,
    deleteShape,
    handleSavedAreas,
    mapDispatch,
}: ConflictAreasProps): {
    onConflictAreaStyleLoad: (map: mapboxgl.Map) => void;
    actions: Record<"isResizing" | "isDragging", boolean>;
} => {
    const dispatch = useDispatch();

    const openModal = compose(dispatch, modalActions.openModal);
    const closeModal = compose(dispatch, modalActions.closeModal);
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md")
    );

    const [actions, setActions] = useState<
        Record<"isResizing" | "isDragging", boolean>
    >({
        isResizing: false,
        isDragging: false,
    });

    const isSavedArea =
        !isEmpty(savedPolygons.polygons) &&
        isEqual(savedPolygons.source, SavedPolygonSource.savedArea);

    const isFourthScenario = isEqual(savedPolygons.isConflict, true);

    const setConflictAreaLayer = (
        conflicts?:
            | GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[]
            | null
    ) => {
        const source = map.current?.getSource(
            "saved-polygons-conflicts"
        ) as GeoJSONSource;

        if (source && isLoaded && map.current) {
            if (
                !isLayerPresent({
                    layerId: "saved-polygons-conflicts-fill",
                    map: map.current,
                    isLoaded,
                })
            ) {
                map.current.addLayer({
                    id: "saved-polygons-conflicts-fill",
                    type: "fill",
                    source: "saved-polygons-conflicts",
                    interactive: false,
                    layout: {
                        visibility: "visible",
                    },
                    paint: {
                        "fill-color": CONFLICT_SHAPE_COLOR,
                        "fill-opacity": 0.5,
                    },
                });
            }

            source.setData({
                type: "FeatureCollection",
                features: isNil(conflicts) ? [] : conflicts,
            });
        }
    };

    const setMapPrompt = (p: MapPopupType) => {
        dispatch(
            toasterActions.openMapPopup({
                ...p,
                sx: {
                    height: (theme) => theme.spacing(MAP_TOOLTIP_HEIGHT / 4),
                    ...p.sx,
                },
            })
        );
    };

    const open = ({
        onClickAccept,
        onClickDecline,
    }: { onClickAccept: () => void; onClickDecline: () => void }) => {
        openModal({
            modalType: "new-dialog",
            component: (
                <ConflictAreasModal
                    onClickDecline={() => {
                        // Unselect saved area
                        handleSavedAreas({ unselect: true });
                        onClickDecline();
                        closeModal();
                    }}
                    onClickAccept={() => {
                        // Discard conflict areas
                        onClickAccept();
                        handleSavedAreas({ unselect: false });
                        if (map.current) {
                            removeCustomLayers(map.current);
                        }
                        closeModal();
                    }}
                />
            ),
            dialogProps: {
                variant: "centered-small",
                onClose: undefined,
            },
        });
    };

    const setPreviousState = (
        features: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[]
    ) => {
        mapDispatch?.({
            type: "SET_PREVIOUS_STATE",
            values: {
                featureCollection: {
                    type: "FeatureCollection",
                    features,
                },
            },
        });
    };

    /*
     * Scenario 1: Creating a new shape that partly overlaps with the outside area.
     */
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (map.current && isSavedArea && isEqual(isFourthScenario, false)) {
            const handleMapConflicts = (
                e: DrawCreateEvent | DrawRenderEvent
            ) => {
                const isCreationEvent = e.type === "draw.create";

                const selected =
                    draw.current && isLoaded
                        ? draw.current.getSelected()
                        : undefined;

                const currentShape =
                    selected && !isEmpty(selected.features)
                        ? selected.features[0]
                        : undefined;

                const shape = isCreationEvent
                    ? e.features[0]
                    : currentShape
                      ? currentShape
                      : last(getMapFeatures(draw, isLoaded));

                const { intersection, difference } = getConflictAreas({
                    shape,
                    savedAreas: savedPolygons.polygons,
                });

                const isDrawingInOutsideArea =
                    shape && intersection && isNil(difference);

                if (intersection) {
                    setConflictAreaLayer([intersection]);
                    setMapPrompt({
                        tooltipArrow: false,
                        additionalMessage: "red area will be discarded",
                        sx: {
                            height: isDrawingInOutsideArea
                                ? (theme) => theme.spacing(16)
                                : undefined,
                            width: isDrawingInOutsideArea
                                ? (theme) =>
                                      theme.spacing(MAP_TOOLTIP_WIDTH / 2)
                                : undefined,
                        },
                    });
                } else {
                    setConflictAreaLayer();
                }

                if (isCreationEvent && shape && difference) {
                    const f = setDrawFeature({
                        feature: difference,
                        draw,
                        isLoaded,
                        currentShapeId: shape.id,
                    });
                    setMapPrompt({
                        additionalMessage: undefined,
                        tooltipArrow: false,
                        sx: {
                            display: "none",
                            height: undefined,
                            width: undefined,
                        },
                    });
                    setPreviousState(f);
                }

                if (isCreationEvent && isDrawingInOutsideArea) {
                    setTimeout(() => {
                        try {
                            if (isString(shape.id)) {
                                draw.current?.delete(shape.id);
                                deleteShape(shape.id);
                                setPreviousState(
                                    pipe(
                                        getMapFeatures(draw, isLoaded),
                                        A.filter((f) => f.id !== shape.id)
                                    )
                                );

                                setConflictAreaLayer();
                                setMapPrompt({
                                    additionalMessage: undefined,
                                    tooltipArrow: false,
                                    sx: {
                                        display: "none",
                                        height: undefined,
                                        width: undefined,
                                    },
                                });
                            }
                        } catch {
                            //
                        }
                    }, 100);
                }
            };

            map.current.on("draw.render", handleMapConflicts);
            map.current.on("draw.create", handleMapConflicts);

            return () => {
                if (map.current) {
                    map.current.off("draw.render", handleMapConflicts);
                    map.current.off("draw.create", handleMapConflicts);
                }
            };
        }
    }, [
        savedPolygons,
        isFourthScenario,
        isLoaded,
        isSelectMode,
        isSavedArea,
        draw,
    ]);

    /*
     * Scenario 2: Resizing a shape outside of the saved area.
     */
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (
            isSavedArea &&
            isEqual(isFourthScenario, false) &&
            isEqual(isSelectMode, false)
        ) {
            const { cleanup } = resize({
                map: map.current,
                draw: draw.current,
                isLoaded,
                onResize: ({
                    cursor,
                    canvas,
                    isResizing,
                    isMidpoint,
                    feature,
                }) => {
                    const left = cursor ? cursor.x : 0;
                    const top = (cursor ? cursor.y : 0) + 20;

                    setActions((p) => ({ ...p, isResizing }));

                    if (canvas) {
                        canvas.style.cursor = "pointer";
                    }

                    // It seems that draw.render doesn't get triggered when it's a midpoint,
                    // so we need to set the intersection's UI here.
                    if (isEqual(isMidpoint, true)) {
                        const { intersection } = getConflictAreas({
                            shape: feature,
                            savedAreas: savedPolygons.polygons,
                        });

                        if (intersection) {
                            setConflictAreaLayer([intersection]);
                            setMapPrompt({
                                tooltipArrow: false,
                                message: "Release to update shape",
                                additionalMessage: "red area will be discarded",
                                sx: {
                                    display: "block",
                                    left: `${left}px`,
                                    top: `${top}px`,
                                    cursor: "pointer",
                                    height: undefined,
                                    width: undefined,
                                },
                            });
                        } else {
                            setConflictAreaLayer();
                        }
                    } else {
                        setMapPrompt({
                            message: "Release to update shape",
                            tooltipArrow: false,
                            sx: {
                                display: "block",
                                left: `${left}px`,
                                top: `${top}px`,
                                cursor: "pointer",
                            },
                        });
                    }
                },
                onRelease: ({ feature }) => {
                    const { difference } = getConflictAreas({
                        shape: feature,
                        savedAreas: savedPolygons.polygons,
                    });

                    if (feature && difference) {
                        const f = setDrawFeature({
                            feature: difference,
                            draw,
                            isLoaded,
                            currentShapeId: feature.id,
                        });

                        setConflictAreaLayer();
                        setPreviousState(f);
                    }

                    if (feature && isString(feature.id) && isNull(difference)) {
                        setTimeout(() => {
                            try {
                                if (isString(feature.id)) {
                                    draw.current?.delete(feature.id);
                                    deleteShape(feature.id);
                                    setPreviousState(
                                        pipe(
                                            getMapFeatures(draw, isLoaded),
                                            A.filter((f) => f.id !== feature.id)
                                        )
                                    );

                                    setConflictAreaLayer();
                                }
                            } catch {
                                //
                            }
                        }, 100);
                    }

                    setActions((p) => ({ ...p, isResizing: false }));

                    setMapPrompt({
                        message: undefined,
                        additionalMessage: undefined,
                        tooltipArrow: false,
                        sx: {
                            display: "none",
                        },
                    });
                },
            });

            return () => {
                cleanup();
            };
        }
    }, [
        map.current,
        savedPolygons,
        isFourthScenario,
        isLoaded,
        isSelectMode,
        isSavedArea,
        draw,
    ]);

    /*
     * Scenario 3: Dragging a shape outside of the saved area.
     */
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (
            isSavedArea &&
            isEqual(isFourthScenario, false) &&
            isEqual(isSelectMode, true)
        ) {
            const { cleanup } = drag({
                map: map.current,
                draw: draw.current,
                isLoaded,
                mode: isSelectMode,
                onDrag: ({ canvas, feature }) => {
                    if (map.current && feature) {
                        const { left, top } = getPolygonTooltipPosition({
                            polygon: feature,
                            map: map.current,
                            position: "bottom",
                        });

                        if (canvas) {
                            canvas.style.cursor = "pointer";
                        }

                        setMapPrompt({
                            message: "Release to move shape",
                            tooltipArrow: false,
                            sx: {
                                display: "block",
                                left: `${left}px`,
                                top: `${top}px`,
                                cursor: "pointer",
                                height: undefined,
                                width: undefined,
                            },
                        });

                        setActions((p) => ({ ...p, isDragging: !!feature }));
                    }
                },
                onRelease: ({ feature }) => {
                    const { difference } = getConflictAreas({
                        shape: feature,
                        savedAreas: savedPolygons.polygons,
                    });

                    if (feature && difference) {
                        const f = setDrawFeature({
                            feature: difference,
                            draw,
                            isLoaded,
                            currentShapeId: feature.id,
                        });

                        setPreviousState(f);

                        if (isPolygon(difference) && isString(feature.id)) {
                            const selected = pipe(
                                getMapFeatures(draw, isLoaded),
                                A.filter((f) => f.properties?.selected === true)
                            );

                            handleSelectedShapes({
                                type: "FeatureCollection",
                                features: pipe(
                                    selected,
                                    A.map((f) =>
                                        f.id === feature.id
                                            ? ({
                                                  ...f,
                                                  geometry: {
                                                      ...f.geometry,
                                                      coordinates:
                                                          difference.geometry
                                                              .coordinates,
                                                  },
                                              } as GeoJSON.Feature<
                                                  GeoJSON.Geometry,
                                                  GeoJSON.GeoJsonProperties
                                              >)
                                            : f
                                    )
                                ),
                            });
                        }

                        setConflictAreaLayer();
                    }

                    if (feature && isString(feature.id) && isNull(difference)) {
                        if (map.current && isMobile) {
                            map.current.setFilter(
                                `map-shapes-fill-${CustomShapeSource.select}`,
                                ["!=", ["get", "featureId"], feature.id]
                            );
                        }

                        setTimeout(() => {
                            try {
                                if (isString(feature.id)) {
                                    draw.current?.delete(feature.id);
                                    deleteShape(feature.id);
                                    handleSelectedShapes(feature);
                                    setConflictAreaLayer();
                                    setPreviousState(
                                        pipe(
                                            getMapFeatures(draw, isLoaded),
                                            A.filter((f) => f.id !== feature.id)
                                        )
                                    );
                                }
                            } catch {
                                //
                            }
                        }, 100);
                    }

                    setMapPrompt({
                        message: undefined,
                        additionalMessage: undefined,
                        tooltipArrow: false,
                        sx: {
                            display: "none",
                        },
                    });

                    setConflictAreaLayer();

                    setActions((p) => ({ ...p, isDragging: false }));
                },
            });

            return () => {
                cleanup();
            };
        }
    }, [
        map.current,
        savedPolygons,
        isFourthScenario,
        isLoaded,
        isSelectMode,
        isSavedArea,
        draw,
    ]);

    /*
     * Scenario 4: Drawing shapes then selecting a saved area.
     */
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (isSavedArea && isEqual(isFourthScenario, true)) {
            const conflicts = pipe(
                getMapFeatures(draw, isLoaded),
                A.map((shape) =>
                    getConflictAreas({
                        shape,
                        savedAreas: savedPolygons.polygons,
                    })
                )
            );

            const intersections = pipe(
                conflicts,
                A.map(({ intersection }) => intersection),
                compact
            );

            if (!isEmpty(intersections)) {
                setConflictAreaLayer(intersections);
                open({
                    onClickAccept: () => {
                        conflicts.map(({ difference, shape }) => {
                            if (difference && shape) {
                                const f = setDrawFeature({
                                    feature: difference,
                                    draw,
                                    isLoaded,
                                    currentShapeId: shape.id,
                                });

                                setPreviousState(f);
                            }

                            if (
                                isNull(difference) &&
                                shape &&
                                isString(shape.id)
                            ) {
                                draw.current?.delete(shape.id);
                                deleteShape(shape.id);

                                setPreviousState(
                                    pipe(
                                        getMapFeatures(draw, isLoaded),
                                        A.filter((f) => f.id !== shape.id)
                                    )
                                );
                            }
                        });

                        setConflictAreaLayer();
                    },
                    onClickDecline: () => {
                        setConflictAreaLayer();
                    },
                });
            } else {
                setConflictAreaLayer();
            }
        }
    }, [savedPolygons, isFourthScenario, isLoaded, isSavedArea, draw]);

    const onConflictAreaStyleLoad = (map: mapboxgl.Map) => {
        map.addSource("saved-polygons-conflicts", {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [],
            },
        });
    };

    return { onConflictAreaStyleLoad, actions };
};
