import {
    LoadingBar,
    SelectableTreeMenu,
    SelectableTreeMenuItem,
    Stack,
} from "@biggeo/bg-ui/lab";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import * as A from "fp-ts/Array";

import { pipe } from "fp-ts/lib/function";

import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import startCase from "lodash/startCase";
import { handleSelectedAreas } from "../utils/utils";

export const SavedAreasSubmenu = ({
    filterItems,
    selectSavedArea,
}: {
    readonly filterItems: readonly SelectableTreeMenuItem[];
    readonly selectSavedArea: (
        items: readonly SelectableTreeMenuItem[]
    ) => void;
}) => {
    return isEmpty(filterItems) ? (
        <LoadingBar />
    ) : (
        <Stack width="100%">
            {pipe(
                filterItems,
                toNonReadonlyArray,
                A.map((item) => {
                    if (!item.subItems) return [];

                    const title =
                        typeof item.title === "string" &&
                        isEqual(
                            item.title.toLowerCase(),
                            "Saved Areas".toLowerCase()
                        )
                            ? startCase(item.title.toLowerCase())
                            : item.title;

                    return (
                        <SelectableTreeMenu
                            showDivider
                            key={item.id}
                            menuItem={{
                                ...item,
                                title,
                            }}
                            onClick={({ id, type }) => {
                                const filters = handleSelectedAreas({
                                    ids: [id],
                                    type,
                                    items: filterItems,
                                });

                                selectSavedArea(filters);
                            }}
                        />
                    );
                })
            )}
        </Stack>
    );
};
