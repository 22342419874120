import { CollectionProgressStatus } from "@biggeo/bg-server-lib/datascape-ai";
import {
    Button,
    DragAndDropContext,
    DroppableColumn,
    EmptyState,
    Stack,
} from "@biggeo/bg-ui/lab";
import { ActionKeyOutline } from "@biggeo/bg-ui/lab/icons";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import { DatasourceDownloadProgressWrapper } from "../../common/components/DatasourceDownloadProgressWrapper";
import { PreviewDatasetCellItem } from "../../common/components/PreviewDatasetCellItem";
import { SetDatasetContextType } from "../../map/mapbox/context/context-utils";
import { MapContextDataset } from "../../map/mapbox/context/map";
import { setDatasetVisibility } from "../../map/mapbox/utils/data-layers-utils";

export type LinearGradientWithId = {
    offset: number;
    color: string;
    id: number;
};

export const ExploreDatasetsView = ({
    datasets,
    exploreDataset,
    onClickPreviewInfo,
    onEdit,
    onRemove,
    onView,
    addRemoveDataset,
    updateDataset,
    reorderDatasets,
    map,
    isLoaded,
}: {
    readonly datasets: MapContextDataset[];
    readonly exploreDataset: () => void;
    readonly onClickPreviewInfo: (
        input: E.Either<
            {
                marketplaceDatasetId: string;
            },
            {
                dataSourceId: string;
            }
        >
    ) => void;
    readonly onEdit?: (id: string) => void;
    readonly onRemove?: (id: string) => void;
    readonly onView?: (id: string) => void;
    readonly addRemoveDataset: (id: string) => void;
    readonly map: React.MutableRefObject<mapboxgl.Map | null>;
    readonly isLoaded: boolean;
} & Pick<SetDatasetContextType, "updateDataset" | "reorderDatasets">) => {
    const handleVisibility = (item: MapContextDataset) => {
        if (map.current) {
            setDatasetVisibility({
                map: map.current,
                isLoaded,
                prefix: item.dataSource.id,
                levelSets: [],
                visibility: item.isVisible ? "none" : "visible",
                points: {
                    shape: item.mapTemplateDataset?.styles?.customMarker
                        ? undefined
                        : item.mapTemplateDataset?.styles?.shape || undefined,
                    customMarker:
                        item.mapTemplateDataset?.styles?.customMarker ||
                        undefined,
                },
            });
        }

        updateDataset({
            dataSourceId: item.dataSource.id,
            dataset: {
                isVisible: !item.isVisible,
            },
        });
    };

    return (
        <Stack
            gap={3}
            sx={{
                width: "100%",
                padding: 3,
                cursor: "pointer",
            }}
        >
            {pipe(
                datasets,
                O.fromPredicate((x) => !isEmpty(x)),
                O.foldW(
                    () => (
                        <EmptyState
                            icon={<ActionKeyOutline size={"md"} />}
                            title={"No Datasets To Preview"}
                            subtitle={
                                "When datasets from the marketplace are ready to be previewed, they will appear here"
                            }
                            buttonText={"Data Marketplace"}
                            buttonIcon={<ActionKeyOutline size={"sm"} />}
                            onClick={() => exploreDataset()}
                            border={false}
                        />
                    ),
                    (data) => (
                        <>
                            <Button
                                startNode={<ActionKeyOutline />}
                                onClick={() => exploreDataset()}
                                variant="outlined"
                            >
                                Data Marketplace
                            </Button>
                            <DragAndDropContext
                                onDragEnd={(result) => {
                                    if (!result.destination || !data) {
                                        return;
                                    }

                                    const items = Array.from(data);
                                    const [reorderedItem] = items.splice(
                                        result.source.index,
                                        1
                                    );
                                    items.splice(
                                        result.destination.index,
                                        0,
                                        reorderedItem
                                    );

                                    reorderDatasets(
                                        items.map((i) => i.dataSource.id)
                                    );
                                }}
                            >
                                <DroppableColumn droppableId="explore-datasets">
                                    <Stack
                                        sx={{
                                            "& > *": {
                                                marginY: 1,
                                            },
                                        }}
                                    >
                                        {pipe(
                                            data,
                                            toNonReadonlyArray,
                                            A.mapWithIndex((index, dataset) => (
                                                <DatasourceDownloadProgressWrapper
                                                    datasourceId={
                                                        dataset.dataSource.id
                                                    }
                                                    key={dataset.dataSource.id}
                                                    addRemoveDataset={
                                                        addRemoveDataset
                                                    }
                                                    updateDataset={
                                                        updateDataset
                                                    }
                                                >
                                                    {({ progress, status }) => (
                                                        <PreviewDatasetCellItem
                                                            key={
                                                                dataset
                                                                    .dataSource
                                                                    .id
                                                            }
                                                            visibility={
                                                                dataset.isVisible
                                                            }
                                                            setVisibility={() => [
                                                                handleVisibility(
                                                                    dataset
                                                                ),
                                                            ]}
                                                            disabled={
                                                                dataset
                                                                    .dataSource
                                                                    .progress
                                                                    ? dataset
                                                                          .dataSource
                                                                          .progress <
                                                                      100
                                                                    : status !==
                                                                      CollectionProgressStatus.complete
                                                            }
                                                            index={index}
                                                            dataset={{
                                                                id: dataset
                                                                    .dataSource
                                                                    .id,
                                                                name:
                                                                    dataset
                                                                        .dataSource
                                                                        .label ||
                                                                    dataset
                                                                        .dataSource
                                                                        .tableName,
                                                                image:
                                                                    dataset
                                                                        .dataSource
                                                                        .icon ||
                                                                    "",
                                                                progress:
                                                                    dataset
                                                                        .dataSource
                                                                        .progress ??
                                                                    Number(
                                                                        progress
                                                                    ),
                                                            }}
                                                            onClickCard={() => {
                                                                onClickPreviewInfo(
                                                                    E.right({
                                                                        dataSourceId:
                                                                            dataset
                                                                                .dataSource
                                                                                .id,
                                                                    })
                                                                );
                                                            }}
                                                            onEdit={onEdit}
                                                            onView={onView}
                                                            onRemove={onRemove}
                                                        />
                                                    )}
                                                </DatasourceDownloadProgressWrapper>
                                            ))
                                        )}
                                    </Stack>
                                </DroppableColumn>
                            </DragAndDropContext>
                        </>
                    )
                )
            )}
        </Stack>
    );
};
