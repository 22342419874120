import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import mapboxgl from "mapbox-gl";
import { compose } from "redux";
import { useMap } from "../../mapbox/context/map.ts";
import { getFilterLayerIds } from "../../mapbox/utils/filtered-data-layers-utils.ts";
import { mapTooltipActions, useDataPointTooltipState } from "./model.ts";

export const dataPointTooltipId = "data-point-tooltip";
export const dataPointTooltipCloseIconId = "data-point-tooltip-close-icon";
export const heatmapTooltipId = "heatmap-tooltip";

const disableMapInteractions = (map: mapboxgl.Map) => {
    map.scrollZoom.disable();
    map.boxZoom.disable();
    map.touchZoomRotate.disable();
};

const enableMapInteractions = (map: mapboxgl.Map) => {
    map.scrollZoom.enable();
    map.boxZoom.enable();
    map.touchZoomRotate.enable();
};

const open = (
    tooltipContainer: HTMLElement,
    map: mapboxgl.Map,
    lngLat: mapboxgl.LngLat
) => {
    new mapboxgl.Marker(tooltipContainer, {
        anchor: "bottom",
        offset: [2, -12],
    })
        .setLngLat(lngLat)
        .addTo(map);
    tooltipContainer.style.display = "block";
};
const close = (tooltipContainer: HTMLElement) => {
    tooltipContainer.style.display = "none";
};

export const useDataPointTooltip = () => {
    const { datasets, map, filters } = useMap();
    const mapInstance = map.current;
    const tooltipContainer = document.getElementById(dataPointTooltipId);
    const tooltipCloseIcon = document.getElementById(
        dataPointTooltipCloseIconId
    );

    const dispatch = useDispatch();

    const setSelectedDataPoint = compose(
        dispatch,
        mapTooltipActions.setSelectedDataPoint
    );

    const filterLayers = getFilterLayerIds({
        type: "point",
        suffixes: pipe(
            filters.map((f) => f.id),
            A.concat(["preview"])
        ),
    });

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!mapInstance || !tooltipContainer) {
            return;
        }
        const canvas = mapInstance.getCanvas();

        const onClick = (
            e: (mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent) & {
                features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
            } & mapboxgl.EventData
        ) => {
            if (mapInstance.getZoom() < 7) {
                return;
            }

            canvas.style.outline = "none";
            const properties = e.features?.[0]?.properties;

            if (!properties) {
                return;
            }

            const id = JSON.parse(properties?.id);
            const databaseId = properties?.databaseId;
            setSelectedDataPoint({
                id:
                    "uint64" in id
                        ? {
                              uint64: {
                                  low: id.uint64?.low
                                      ? id.uint64.low
                                      : id.uint64,
                              },
                          }
                        : { string: id.string },
                databaseId,
            });

            open(tooltipContainer, mapInstance, e.lngLat);
        };

        tooltipContainer.addEventListener("mouseenter", () =>
            disableMapInteractions(mapInstance)
        );
        tooltipContainer.addEventListener("mouseleave", () =>
            enableMapInteractions(mapInstance)
        );
        tooltipContainer.addEventListener("click", (e) => e.stopPropagation());
        tooltipCloseIcon?.addEventListener("click", () =>
            close(tooltipContainer)
        );
        canvas.addEventListener("click", () => close(tooltipContainer));

        for (const dataset of datasets) {
            for (const layer of [
                `${dataset.dataSource.id}-points-icon-oval`,
                `${dataset.dataSource.id}-points-icon-square`,
                `${dataset.dataSource.id}-custom-marker`,
                `${dataset.dataSource.id}-polygons`,
                `${dataset.dataSource.id}-lines`,
            ]) {
                mapInstance.on("click", layer, onClick);
                mapInstance.on("mouseenter", layer, () => {
                    canvas.style.cursor = "pointer";
                });
                mapInstance.on("mouseleave", layer, () => {
                    canvas.style.cursor = "";
                });
                mapInstance.on("touchstart", layer, onClick);
            }
        }

        for (const layer of filterLayers) {
            mapInstance.on("click", layer, onClick);
            mapInstance.on("mouseenter", layer, () => {
                canvas.style.cursor = "pointer";
            });
            mapInstance.on("mouseleave", layer, () => {
                canvas.style.cursor = "";
            });
            mapInstance.on("touchstart", layer, onClick);
        }

        return () => {
            tooltipContainer.removeEventListener("mouseenter", () =>
                disableMapInteractions(mapInstance)
            );
            tooltipContainer.removeEventListener("mouseleave", () =>
                enableMapInteractions(mapInstance)
            );
            tooltipCloseIcon?.removeEventListener("click", () =>
                close(tooltipContainer)
            );
            canvas.removeEventListener("click", () => close(tooltipContainer));

            for (const dataset of datasets) {
                for (const layer of [
                    `${dataset.dataSource.id}-points-icon-oval`,
                    `${dataset.dataSource.id}-points-icon-square`,
                    `${dataset.dataSource.id}-custom-marker`,
                    `${dataset.dataSource.id}-polygons`,
                    `${dataset.dataSource.id}-lines`,
                ]) {
                    mapInstance.off("click", layer, onClick);
                    mapInstance.off("touchstart", layer, onClick);
                    mapInstance.off("mouseenter", layer, () => {
                        canvas.style.cursor = "pointer";
                    });
                    mapInstance.off("mouseleave", layer, () => {
                        canvas.style.cursor = "";
                    });
                }
            }

            for (const layer of filterLayers) {
                mapInstance.off("click", layer, onClick);
                mapInstance.off("touchstart", layer, onClick);
                mapInstance.off("mouseenter", layer, () => {
                    canvas.style.cursor = "pointer";
                });
                mapInstance.off("mouseleave", layer, () => {
                    canvas.style.cursor = "";
                });
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        datasets,
        mapInstance,
        tooltipContainer,
        tooltipCloseIcon,
        filterLayers,
    ]);

    return useDataPointTooltipState();
};

export const useHeatMapTooltip = () => {
    const { datasets, map, filters } = useMap();
    const mapInstance = map.current;
    const tooltipContainer = document.getElementById(heatmapTooltipId);

    const filterLayers = getFilterLayerIds({
        type: "data",
        suffixes: pipe(
            filters.map((f) => f.id),
            A.concat(["preview"])
        ),
    });

    useEffect(() => {
        if (!mapInstance || !tooltipContainer) {
            return;
        }
        const canvas = mapInstance.getCanvas();

        const onClick = (
            e: (mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent) & {
                features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
            } & mapboxgl.EventData
        ) => {
            if (mapInstance.getZoom() >= 14) {
                return;
            }

            canvas.style.outline = "none";

            open(tooltipContainer, mapInstance, e.lngLat);
        };

        tooltipContainer.addEventListener("mouseenter", () =>
            disableMapInteractions(mapInstance)
        );
        tooltipContainer.addEventListener("mouseleave", () =>
            enableMapInteractions(mapInstance)
        );
        tooltipContainer.addEventListener("click", (e) => e.stopPropagation());
        canvas.addEventListener("click", () => close(tooltipContainer));

        for (const dataset of datasets) {
            mapInstance.on(
                "click",
                `${dataset.dataSource.id}-aggregate`,
                onClick
            );
            mapInstance.on(
                "mouseenter",
                `${dataset.dataSource.id}-aggregate`,
                () => {
                    canvas.style.cursor = "pointer";
                }
            );
            mapInstance.on(
                "mouseleave",
                `${dataset.dataSource.id}-aggregate`,
                () => {
                    canvas.style.cursor = "";
                }
            );
            mapInstance.on(
                "touchstart",
                `${dataset.dataSource.id}-aggregate`,
                onClick
            );
        }

        for (const layer of filterLayers) {
            mapInstance.on("click", layer, onClick);
            mapInstance.on("mouseenter", layer, () => {
                canvas.style.cursor = "pointer";
            });
            mapInstance.on("mouseleave", layer, () => {
                canvas.style.cursor = "";
            });
            mapInstance.on("touchstart", layer, onClick);
        }

        return () => {
            tooltipContainer.removeEventListener("mouseenter", () =>
                disableMapInteractions(mapInstance)
            );
            tooltipContainer.removeEventListener("mouseleave", () =>
                enableMapInteractions(mapInstance)
            );
            canvas.removeEventListener("click", () => close(tooltipContainer));

            for (const dataset of datasets) {
                mapInstance.off(
                    "click",
                    `${dataset.dataSource.id}-aggregate`,
                    onClick
                );
                mapInstance.off(
                    "touchstart",
                    `${dataset.dataSource.id}-aggregate`,
                    onClick
                );
                mapInstance.off(
                    "mouseenter",
                    `${dataset.dataSource.id}-aggregate`,
                    () => {
                        canvas.style.cursor = "pointer";
                    }
                );
                mapInstance.off(
                    "mouseleave",
                    `${dataset.dataSource.id}-aggregate`,
                    () => {
                        canvas.style.cursor = "";
                    }
                );
            }

            for (const layer of filterLayers) {
                mapInstance.off("click", layer, onClick);
                mapInstance.off("mouseenter", layer, () => {
                    canvas.style.cursor = "pointer";
                });
                mapInstance.off("mouseleave", layer, () => {
                    canvas.style.cursor = "";
                });
                mapInstance.off("touchstart", layer, onClick);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datasets, mapInstance, tooltipContainer, filterLayers]);

    return useDataPointTooltipState().selectedDataPoint;
};
