import { ExportProgressStatus } from "@biggeo/bg-server-lib/datascape-ai";
import { Stack } from "@biggeo/bg-ui/lab";
import { ExportDatasetCardContainer } from "../containers/ExportDatasetCardContainer";

// export enum ProgressStatus {
//     failed = "Failed",
//     downloading = "Downloading",
//     success = "Success",
// }
export type BasicExportDataType = {
    id: string;
    name: string;
    progress: number;
    status: ExportProgressStatus;
    results?: number;
    rows?: number;
    size?: number;
    from?: string;
    type?: string;
    image?: string;
    select?: boolean;
    ddlCommand?: string;
    dmlCommand?: string;
};

export const DatasetCardList = <T extends BasicExportDataType>({
    dataList,
    onSelect,
    selectedIds,
}: {
    readonly dataList: readonly T[];
    readonly onSelect: (i: string) => void;
    readonly selectedIds: readonly string[];
}) => {
    return (
        <Stack gap={4}>
            {dataList.map((data) => {
                return (
                    <ExportDatasetCardContainer
                        data={data}
                        key={data.id}
                        onSelect={onSelect}
                        selectedIds={selectedIds}
                    />
                );
            })}
        </Stack>
    );
};
