import {
    DataSource,
    useFindAllDataSourcesQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { GridColDef, WithLoading } from "@biggeo/bg-ui";
import {
    Box,
    Button,
    ColFilterType,
    DataGridContainer,
    EmptyScreen,
    Grid,
    IFilterSearchPaginate,
    IconAvatar,
    MemoizedField,
    ProgressBar,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import {
    ActionKeyOutline,
    BigGeoLogo,
    KeyOutline,
} from "@biggeo/bg-ui/lab/icons";

import isEmpty from "lodash/isEmpty";
import { Link } from "react-router-dom";

import isNull from "lodash/isNull";
import { useState } from "react";
import { Routes } from "../../navigation/redux/model";
import { formatNumberWithCommas } from "../../utils/utils";

export const UnAvailableDatasetsContainer = ({
    dataSources,
    total,
    tab,
    loading,
    filterSearchPaginateProps,
    toPage,
}: {
    readonly dataSources?: readonly DataSource[];
    readonly total?: number;
    readonly tab: "processing" | "unIndexed";
    readonly isRunningOnSF: boolean;
    readonly loading: boolean;
    readonly filterSearchPaginateProps: IFilterSearchPaginate;
    readonly isSNP: boolean;
    readonly toPage: (route: string) => void;
}) => {
    const [isIndexing, setIsIndexing] = useState<boolean>(false);

    const { remote: _r } = useFindAllDataSourcesQuery({
        variables: {
            input: { isConnected: false },
        },
        onCompleted: (data) => {
            const processingDataSources = data.findAllDataSources.filter(
                (dataSource) => !isNull(dataSource.progress)
            );

            isEmpty(processingDataSources)
                ? setIsIndexing(false)
                : setIsIndexing(true);
        },
    });

    const title =
        tab === "processing"
            ? "No Datasets Are Processing"
            : "No Un-Indexed Data";

    const unavailableDatasetsColumns = (
        tab: "processing" | "unIndexed",
        loading: boolean
    ): GridColDef<DataSource>[] => [
        {
            field: "label",
            headerName: "Name",
            flex: 1,
            minWidth: 220,
            filterable: true,
            type: ColFilterType.string,
            renderCell: (params) => (
                <Stack
                    sx={{
                        breakpoints: {
                            cmd: { minWidth: (theme) => theme.spacing(94.5) },
                        },
                    }}
                >
                    <Typography variant="body3" fontWeight="semibold">
                        {params.row.label || params.row.tableName}
                    </Typography>
                    <Typography
                        variant="body3"
                        sx={{
                            color: (theme) =>
                                theme.palette.disabled.onContainer,
                        }}
                    >
                        {params.row.description}
                    </Typography>
                </Stack>
            ),
        },
        {
            field: "src",
            headerName: "Src",
            minWidth: 64,
            sortable: false,
            headerAlign: "center",
            renderCell: (params) => (
                <Box
                    sx={{ display: "flex", justifyContent: "center" }}
                    width="100%"
                >
                    {params.row.src ? (
                        <Box
                            sx={{
                                height: (theme) => theme.spacing(6),
                                width: (theme) => theme.spacing(6),
                                borderRadius: 4,
                                backgroundImage: `url('${params.row.src}')`,
                                backgroundSize: "contain",
                            }}
                        />
                    ) : (
                        <IconAvatar color="primary" size="xs">
                            <BigGeoLogo />
                        </IconAvatar>
                    )}
                </Box>
            ),
        },
        {
            field: "size",
            headerName: "Size",
            width: 125,
            sortable: false,
            type: ColFilterType.number,
            filterable: true,
            headerAlign: "left",
            renderCell: (params) => (
                <MemoizedField
                    disable={Boolean(!params.row.compute)}
                    title={formatNumberWithCommas(params.row.size)}
                />
            ),
        },
        {
            field: "progress",
            headerName: "Indexing progress",
            minWidth: 225,
            flex: 0.85,
            filterable: true,
            type: ColFilterType.number,
            headerAlign: "left",
            renderCell: (params) => {
                const usage = formatNumberWithCommas(
                    Number(
                        (
                            ((params.row.progress || 0) * params.row.size) /
                            100
                        ).toFixed(0)
                    )
                );
                return (
                    <Stack sx={{ width: "100%" }} gap={2}>
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Typography variant="body3" fontWeight="semibold">
                                {tab === "processing"
                                    ? `Processing ${usage} of ${formatNumberWithCommas(params.row.size)}`
                                    : "Click index to get started"}
                            </Typography>{" "}
                            <Typography variant="body4" fontWeight="semibold">
                                {`${params.row.progress || 0}% done`}
                            </Typography>
                        </Grid>
                        <ProgressBar
                            color="success"
                            value={params.row.progress || 0}
                            sx={{
                                height: (theme) => theme.spacing(1.5),
                                borderRadius: 4,
                                backgroundColor: (theme) =>
                                    theme.palette.disabled.main,
                            }}
                        />
                    </Stack>
                );
            },
        },
        {
            field: "actions",
            headerName: "",
            minWidth: 110,
            sortable: true,
            renderCell: (_params) => {
                const isLoading = loading;
                return (
                    <MemoizedField disable={isIndexing}>
                        <Stack sx={{ width: "100%" }}>
                            {tab === "processing" ? (
                                <WithLoading
                                    loading={isLoading}
                                    text="Cancelling..."
                                >
                                    <Button
                                        fullWidth
                                        density="dense"
                                        variant="ghost"
                                        color="info"
                                        sx={{ alignSelf: "center" }}
                                    >
                                        Cancel
                                    </Button>
                                </WithLoading>
                            ) : (
                                <Link to={`/data/index/${_params.row.id}`}>
                                    <WithLoading
                                        loading={isLoading}
                                        text="Indexing..."
                                    >
                                        <Button
                                            color="info"
                                            fullWidth
                                            density="dense"
                                            disabled={isIndexing}
                                            readonly={isIndexing}
                                        >
                                            Index
                                        </Button>
                                    </WithLoading>
                                </Link>
                            )}
                        </Stack>
                    </MemoizedField>
                );
            },
        },
    ];

    return (
        <>
            {!loading &&
            !filterSearchPaginateProps.searchText &&
            isEmpty(filterSearchPaginateProps.filterObject) &&
            total === 0 ? (
                <EmptyScreen
                    title={title}
                    subtitle={
                        "Your datasets that need to be indexed by BigGeo first will appear here."
                    }
                    image={
                        "https://biggeo.blob.core.windows.net/media/DataManagmentEmpty.png"
                    }
                    buttons={[
                        {
                            startNode: <ActionKeyOutline />,
                            onClick: () => {
                                toPage(`${Routes.explore}/datasets`);
                            },
                            children: "Add Dataset",
                            color: "primary",
                            variant: "filled",
                        },
                        {
                            onClick: () => {
                                toPage(Routes.accessKeys);
                            },
                            children: "Dataset Access Key",
                            startNode: <KeyOutline />,
                        },
                    ]}
                />
            ) : (
                <DataGridContainer
                    pinnedColumns={{ right: ["actions"] }}
                    columns={unavailableDatasetsColumns(tab, loading)}
                    rows={dataSources || []}
                    rowCount={total || 0}
                    loading={loading}
                    title={"Data sources"}
                    filterSearchPaginateProps={filterSearchPaginateProps}
                    sx={{
                        borderBottomLeftRadius: 4,
                        borderBottomRightRadius: 4,
                    }}
                />
            )}
        </>
    );
};
