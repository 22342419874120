import {
    LogicOperator,
    useFetchMapTemplateDatasetsExtendedQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Theme, useMediaQuery } from "@biggeo/bg-ui";
import {
    Button,
    LoadingBar,
    Menu,
    MenuItem,
    MenuProps,
    Stack,
    ThumbnailAvatar,
} from "@biggeo/bg-ui/lab";
import { AddCircleOutline } from "@biggeo/bg-ui/lab/icons";
import { match } from "@vividtheory/remotedata";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import { ErrorPage } from "../../../common/components/ErrorPage";
import { MapFilterCriteriaDataset } from "../utils/utils";
import FilterCriteriaDatasetsListEmptyView from "../views/FilterCriteriaDatasetsListEmptyView";

interface IMapTemplateDatasetsMenu extends Pick<MenuProps, "placement"> {
    readonly mapTemplateId: number;
    readonly text?: string;
    readonly onDatasetClick: (i: MapFilterCriteriaDataset) => void;
    readonly omittedDatasets?: string[];
    readonly includedDatasets?: string[];
}

const MapTemplateDatasetsMenu = ({
    mapTemplateId,
    text = "Select Dataset",
    onDatasetClick,
    omittedDatasets,
    placement,
    includedDatasets,
}: IMapTemplateDatasetsMenu) => {
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md")
    );

    const { remote } = useFetchMapTemplateDatasetsExtendedQuery({
        variables: {
            input: {
                fkMapTemplateId: mapTemplateId,
                compute: true,
            },
        },
    });

    return (
        <Menu
            placement={isMobile ? undefined : placement}
            sx={{
                padding: 2,
                maxWidth: 72,
                width: "100%",
                maxHeight: 95,
                gap: 0,
                scrollbarGutter: "auto",
            }}
            content={match(remote, {
                _: () => <LoadingBar />,
                Success: (data) => {
                    return (
                        <Stack>
                            {pipe(
                                data.fetchMapTemplateDatasetsExtended,
                                A.filter(
                                    (dataset) =>
                                        !(omittedDatasets || []).includes(
                                            dataset.dataSource.id
                                        )
                                ),
                                A.filter((dataset) =>
                                    (includedDatasets || []).includes(
                                        dataset.dataSource.id
                                    )
                                ),
                                O.fromPredicate((x) => !isEmpty(x)),
                                O.foldW(
                                    () => (
                                        <FilterCriteriaDatasetsListEmptyView />
                                    ),
                                    A.map((dataset) => {
                                        const label =
                                            dataset.dataSource.label ||
                                            dataset.dataSource.tableName;

                                        return (
                                            <MenuItem
                                                key={
                                                    dataset.mapTemplateDataset
                                                        .id
                                                }
                                                startNode={
                                                    <ThumbnailAvatar
                                                        size="md"
                                                        alt={label}
                                                        square
                                                    />
                                                }
                                                density="dense"
                                                label={label}
                                                onClick={() =>
                                                    onDatasetClick({
                                                        mapTemplateDatasetId:
                                                            dataset
                                                                .mapTemplateDataset
                                                                .id,
                                                        dataSourceId:
                                                            dataset
                                                                .mapTemplateDataset
                                                                .fkDataSourceId,
                                                        label,
                                                        logicOperator:
                                                            LogicOperator.and,
                                                        collection:
                                                            dataset.dataSource
                                                                .collectionName,
                                                        filters: [
                                                            {
                                                                column: undefined,
                                                                type: undefined,
                                                                operator:
                                                                    undefined,
                                                                value: undefined,
                                                            },
                                                        ],
                                                    })
                                                }
                                            />
                                        );
                                    })
                                )
                            )}
                        </Stack>
                    );
                },
                Failure: (err) => <ErrorPage subtitle={err.message} />,
            })}
        >
            <Button
                variant="outlined"
                density="dense"
                endNode={<AddCircleOutline size="xs" />}
            >
                {text}
            </Button>
        </Menu>
    );
};

export default MapTemplateDatasetsMenu;
