import {
    DataProvider,
    EntitlementType,
    MarketplaceDataset,
    VerifiedAccessKeyStatus,
    useCreateDataSourceByAccessKeyMutation,
    useFetchDataProviderByIdLazyQuery,
    useFetchMarketplaceDatasetByIdLazyQuery,
    useVerifyEntitlementAccessKeyLazyQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Theme, WithLoading, useMediaQuery } from "@biggeo/bg-ui";
import {
    Button,
    Divider,
    Grid,
    Severity,
    Stack,
    StickyFabPlacementHelper,
    TextField,
    ThumbnailAvatar,
    Typography,
    textInputBaseClasses,
    thumbnailAvatarClasses,
} from "@biggeo/bg-ui/lab";
import {
    CancelFilled,
    CheckCircle,
    CompareArrowsOutline,
} from "@biggeo/bg-ui/lab/icons";
import { CenteredLayout } from "@biggeo/bg-ui/lab/layouts";
import { Formik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Zod from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { BigGeoIndexedCard } from "../../marketplace/components/BigGeoIndexedCard";
import { Routes } from "../../navigation/redux/model";
import { toasterActions } from "../../toaster/containers/redux/model";

type CheckAccessKey = {
    status: VerifiedAccessKeyStatus;
    accessKey: string;
    message: string;
};

const initialValues = {
    status: VerifiedAccessKeyStatus.Failed,
    accessKey: "",
    message: "",
};

export const OldUnlockDatasetAccessKeyPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md")
    );

    const [enteredKey, setEnteredKey] = useState("");
    const [marketplaceDataset, setMarketplaceDataset] = useState<
        { dataset: MarketplaceDataset; provider: DataProvider } | undefined
    >();

    const {
        executeQuery: verifyEntitlementAccessKey,
        queryReturn: [_, { loading: verifyLoading }],
    } = useVerifyEntitlementAccessKeyLazyQuery();

    const { executeQuery: fetchMarketplaceDatasetById } =
        useFetchMarketplaceDatasetByIdLazyQuery();

    const { executeQuery: fetchDataProviderById } =
        useFetchDataProviderByIdLazyQuery();

    const {
        executeMutation: createDataSourceByAccessKey,
        mutationReturn: [_c, { loading: addAccessKeyLoading }],
    } = useCreateDataSourceByAccessKeyMutation();

    const checked = (values: string) => {
        createDataSourceByAccessKey({
            variables: {
                input: { accessKey: values, type: EntitlementType.Dataset },
            },
            onCompleted: () => {
                dispatch(
                    toasterActions.openToast({
                        open: true,
                        title: "Add dataset successfully",
                        autoHideDuration: 5000,
                        severity: Severity.success,
                    })
                );
                navigate(`${Routes.explore}`);
            },
            onError: () => {
                dispatch(
                    toasterActions.openToast({
                        open: true,
                        title: "An error occur while adding dataset. Please try again later.",
                        autoHideDuration: 5000,
                        severity: Severity.error,
                    })
                );
            },
        });
    };
    return (
        <Formik<CheckAccessKey>
            initialValues={initialValues}
            validateOnMount
            onSubmit={(values, _actions) => {
                checked(values.accessKey);
            }}
            validationSchema={toFormikValidationSchema(
                Zod.object({
                    accessKey: Zod.string(),
                    status: Zod.nativeEnum(VerifiedAccessKeyStatus).refine(
                        (val) => val === VerifiedAccessKeyStatus.Success
                    ),
                })
            )}
        >
            {({ values, setValues, handleSubmit, dirty, isValid }) => {
                const handleCheckKey = () => {
                    verifyEntitlementAccessKey({
                        variables: {
                            input: {
                                accessKey: enteredKey,
                                type: EntitlementType.Dataset,
                            },
                        },

                        onCompleted: (data) => {
                            const response = data.verifyEntitlementAccessKey;
                            if (
                                response.status ===
                                    VerifiedAccessKeyStatus.Success &&
                                response.productId
                            ) {
                                fetchMarketplaceDatasetById({
                                    variables: {
                                        id: response.productId,
                                    },
                                    onCompleted: (mpDataset) => {
                                        fetchDataProviderById({
                                            variables: {
                                                id: mpDataset
                                                    .fetchMarketplaceDatasetById
                                                    .fkProviderId,
                                            },
                                            onCompleted: (provider) =>
                                                setMarketplaceDataset({
                                                    dataset:
                                                        mpDataset.fetchMarketplaceDatasetById,
                                                    provider:
                                                        provider.fetchDataProviderById,
                                                }),
                                        });
                                    },
                                });
                            }
                            setValues({
                                accessKey: enteredKey,
                                status: response.status,
                                message: response.message,
                            });
                        },
                    });
                };
                return (
                    <CenteredLayout>
                        <Stack width={"100%"}>
                            <Stack
                                gap={8}
                                sx={{
                                    border: (theme) =>
                                        `1px solid ${theme.palette.stroke[100]}`,
                                    borderRadius: (theme) => theme.radius.xs3,
                                    breakpoints: {
                                        cmd: {
                                            padding: 8,
                                        },
                                        xs: {
                                            paddingX: 4,
                                            paddingY: 8,
                                        },
                                    },
                                }}
                            >
                                <Stack gap={3} width={"100%"}>
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                        gap={1}
                                        sx={{
                                            paddingRight: (theme) =>
                                                theme.spacing(2),
                                            paddingLeft: (theme) =>
                                                theme.spacing(2),
                                        }}
                                    >
                                        <ThumbnailAvatar
                                            src={
                                                marketplaceDataset?.dataset
                                                    .img || undefined
                                            }
                                            square
                                            size="lg"
                                            sx={{
                                                borderRadius: (theme) =>
                                                    theme.radius.xs3,
                                            }}
                                        />

                                        <CompareArrowsOutline
                                            size="xs"
                                            color="disabled"
                                        />

                                        <ThumbnailAvatar
                                            src={
                                                "https://biggeo.blob.core.windows.net/media/bg-background.png"
                                            }
                                            square
                                            size="lg"
                                            sx={{
                                                [`& .${thumbnailAvatarClasses.image}`]:
                                                    {
                                                        backgroundColor: (
                                                            theme
                                                        ) =>
                                                            theme.palette
                                                                .background
                                                                .main,
                                                    },
                                                borderRadius: (theme) =>
                                                    theme.radius.xs3,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item sx={{ textAlign: "center" }}>
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                            color="background"
                                            colorSet="main"
                                            invertColors
                                            sx={{
                                                display: "inline",
                                            }}
                                        >
                                            Unlock A{" "}
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                            color="primary"
                                            colorSet="main"
                                            sx={{
                                                display: "inline",
                                            }}
                                        >
                                            BigGeo Indexed{" "}
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                            color="background"
                                            colorSet="main"
                                            invertColors
                                            sx={{
                                                display: "inline",
                                            }}
                                        >
                                            Dataset
                                        </Typography>
                                    </Grid>

                                    <Grid item sx={{ textAlign: "center" }}>
                                        <Typography
                                            variant="body3"
                                            fontWeight="regular"
                                            color="background"
                                            colorSet="main"
                                            invertColors
                                            sx={{
                                                display: "inline",
                                            }}
                                        >
                                            A dataset access key unlocks a
                                            dataset access to your account for
                                            use within Datascape. Access keys
                                            are provided by BigGeo{" "}
                                        </Typography>
                                        {isMobile ? (
                                            <Typography
                                                variant="body3"
                                                fontWeight="regular"
                                                color="primary"
                                                colorSet="main"
                                                sx={{
                                                    display: "inline",
                                                    textDecoration: "underline",
                                                }}
                                            >
                                                <Link
                                                    to={`${Routes.bigGeoProductsDatalab}`}
                                                    target="_blank"
                                                >
                                                    data provider partners.
                                                </Link>
                                            </Typography>
                                        ) : (
                                            <Typography
                                                variant="body3"
                                                fontWeight="regular"
                                                color="primary"
                                                colorSet="main"
                                                sx={{
                                                    display: "inline",
                                                    ":hover": {
                                                        textDecoration:
                                                            "underline",
                                                    },
                                                }}
                                            >
                                                <Link
                                                    to={`${Routes.bigGeoProductsDatalab}`}
                                                    target="_blank"
                                                >
                                                    data provider partners.
                                                </Link>
                                            </Typography>
                                        )}
                                    </Grid>
                                </Stack>

                                <Stack width={"100%"} gap={1}>
                                    <TextField
                                        value={enteredKey}
                                        placeholder="Enter Your Access Key"
                                        slotProps={{
                                            inputRoot: {
                                                sx: {
                                                    padding: 4,
                                                    [`& .${textInputBaseClasses["end-node"]}`]:
                                                        {
                                                            flexShrink: 0,
                                                        },
                                                },
                                            },
                                        }}
                                        fullWidth
                                        onChange={(_, v) => {
                                            setValues(initialValues);
                                            setMarketplaceDataset(undefined);
                                            setEnteredKey(v || "");
                                        }}
                                        endNode={
                                            <Grid
                                                sx={{
                                                    display: "flex",
                                                    gap: 2,
                                                }}
                                            >
                                                <Divider
                                                    orientation="vertical"
                                                    color={300}
                                                />
                                                <WithLoading
                                                    loading={verifyLoading}
                                                    text="Checking"
                                                >
                                                    <Button
                                                        variant="ghost"
                                                        color="primary"
                                                        onClick={() => {
                                                            handleCheckKey();
                                                        }}
                                                        fullWidth
                                                        disabled={!enteredKey}
                                                    >
                                                        Check Key
                                                    </Button>
                                                </WithLoading>
                                            </Grid>
                                        }
                                    />
                                    {values.accessKey && (
                                        <Grid
                                            sx={{ display: "flex" }}
                                            alignItems="center"
                                            gap={1}
                                        >
                                            {values.status ===
                                            VerifiedAccessKeyStatus.Failed ? (
                                                <CancelFilled
                                                    size="xxs"
                                                    color={"error"}
                                                />
                                            ) : (
                                                <CheckCircle
                                                    size="xxs"
                                                    color={"success"}
                                                />
                                            )}

                                            <Typography variant="body4">
                                                {values.message}
                                            </Typography>
                                        </Grid>
                                    )}
                                </Stack>
                                <Grid container gap={1} justifyContent="center">
                                    <Link
                                        to="https://datascape.featurebase.app/help/articles/5156192-dataset-access-key#6q5me7btlqe"
                                        target="_blank"
                                    >
                                        <Button variant="minimal">
                                            <Typography
                                                variant="body4"
                                                fontWeight="semibold"
                                            >
                                                Forgot Access key?
                                            </Typography>
                                        </Button>
                                    </Link>
                                </Grid>
                            </Stack>
                            {marketplaceDataset && (
                                <Stack
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.surface.container,
                                        border: (theme) =>
                                            `1px solid ${theme.palette.stroke[100]}`,
                                        marginTop: -4,
                                        borderRadius: (theme) =>
                                            theme.radius.xs3,
                                    }}
                                >
                                    <BigGeoIndexedCard
                                        image={
                                            marketplaceDataset.dataset.img || ""
                                        }
                                        id={marketplaceDataset.dataset.id}
                                        name={marketplaceDataset.dataset.name}
                                        subTitle={
                                            marketplaceDataset.provider.name
                                        }
                                    />
                                </Stack>
                            )}
                        </Stack>
                        <StickyFabPlacementHelper
                            placement="right"
                            sx={{
                                paddingY: 4,
                                backgroundColor: (theme) =>
                                    theme.palette.background.container,
                            }}
                        >
                            <Stack
                                flexDirection="row"
                                justifyContent="flex-end"
                            >
                                <WithLoading
                                    text={"Creating..."}
                                    loading={addAccessKeyLoading}
                                >
                                    <Button
                                        type="submit"
                                        disabled={!isValid || !dirty}
                                        onClick={() => handleSubmit()}
                                    >
                                        Unlock Dataset
                                    </Button>
                                </WithLoading>
                            </Stack>
                        </StickyFabPlacementHelper>
                    </CenteredLayout>
                );
            }}
        </Formik>
    );
};
