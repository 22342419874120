import { EmptyState, Stack } from "@biggeo/bg-ui/lab";
import { LayersOutline } from "@biggeo/bg-ui/lab/icons";
import { Formik } from "formik";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { GeoJSONSource } from "mapbox-gl";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toasterActions } from "../../toaster/containers/redux/model";
import { MapModes, useMap } from "../mapbox/context";
import { MapStylePromptType } from "../mapbox/hooks/popup-hooks";
import { CustomShapeSource } from "../mapbox/hooks/style-hooks";
import { MAP_TOOLTIP_HEIGHT } from "../mapbox/views/MapPromptPopup";
import { getDrawMode } from "../utils/draw-modes";
import {
    onCustomShapeLayerLoad,
    resize,
    setCustomShapeStyling,
} from "../utils/style-utils";
import {
    FunctionType,
    getMapFeatures,
    getPolygonTooltipPosition,
    isPolygonFeature,
} from "../utils/utils";
import MapShapeLayerStyles from "./MapShapeLayerStyles";
import MapShapeLayersView from "./MapShapeLayersView";

export type MapFeature = GeoJSON.Feature<
    GeoJSON.Geometry,
    GeoJSON.GeoJsonProperties
>;

export type MapLayerForm = {
    features: MapFeature[];
    currentFeature?: MapFeature;
};

export interface IMapShapeLayers {
    readonly functionType: FunctionType;
    readonly deleteShape: (id: string) => void;
    readonly exitMapModes: () => void;
}

const MapShapeLayers = ({
    functionType,
    deleteShape,
    exitMapModes,
}: IMapShapeLayers) => {
    const dispatch = useDispatch();
    const {
        draw,
        map,
        isLoaded,
        dispatch: mapDispatch,
        datasets,
        modes,
    } = useMap();
    const features = getMapFeatures(draw, isLoaded);
    const [currentShape, setCurrentShape] = useState<MapFeature | undefined>(
        undefined
    );
    const isSelectMode = modes?.[MapModes.select].isSelectMode || false;
    const isDrawMode =
        isEqual(functionType, FunctionType.polygon) ||
        isEqual(functionType, FunctionType.radius) ||
        isEqual(functionType, FunctionType.square);

    const source = map.current?.getSource(
        CustomShapeSource.customization
    ) as GeoJSONSource;

    const deleteShapeLayer = (id: string, values: MapLayerForm) => {
        draw.current?.changeMode("simple_select", { featureIds: [id] });
        draw.current?.delete(id);
        deleteShape(id);

        source.setData({
            type: "FeatureCollection",
            features: pipe(
                values.features,
                A.filter((f) => f.id !== id)
            ),
        });

        dispatch(
            toasterActions.openToast({
                open: true,
                title: "Shape deleted successfully.",
                autoHideDuration: 5000,
            })
        );

        setTimeout(() => {
            draw.current?.changeMode(getDrawMode(functionType));
        }, 20);
    };

    const setTooltip = (i: {
        show: boolean;
        message?: MapStylePromptType;
        coordinates: {
            top: number;
            left: number;
        };
    }) => {
        const { show, message, coordinates } = i;
        const { top, left } = coordinates;

        dispatch(
            toasterActions.openMapPopup({
                message,
                tooltipArrow: true,
                sx: show
                    ? {
                          display: "block",
                          left: `${left}px`,
                          top: `${top}px`,
                          height: (theme) =>
                              theme.spacing(MAP_TOOLTIP_HEIGHT / 4),
                      }
                    : { display: "none" },
            })
        );
    };

    const setPreviousState = (
        features: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[]
    ) => {
        mapDispatch?.({
            type: "SET_PREVIOUS_STATE",
            values: {
                featureCollection: {
                    type: "FeatureCollection",
                    features,
                },
            },
        });
    };

    const submit = (values: MapLayerForm) => {
        if (values.currentFeature) draw.current?.add(values.currentFeature);

        if (map.current) {
            setCustomShapeStyling({
                map: map.current,
                isLoaded,
                datasets,
                geometries: values.features,
                source,
            });
        }

        setPreviousState(values.features);

        dispatch(
            toasterActions.openToast({
                open: true,
                title: "Shape updated successfully.",
                autoHideDuration: 5000,
            })
        );

        if (values.currentFeature) {
            // To keep the menu open even after submitting
            setCurrentShape(values.currentFeature);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        // Update shape's coordinates as it gets resized
        const { cleanup } = resize({
            map: map.current,
            draw: draw.current,
            isLoaded,
            onResize: ({ feature }) => {
                const styleFeatures = map.current
                    ? map.current.querySourceFeatures(
                          CustomShapeSource.customization
                      )
                    : [];

                const isStyleFeature = styleFeatures
                    .map((f) => f.properties?.featureId)
                    .includes(feature?.id);

                if (isStyleFeature) {
                    const f = pipe(
                        getMapFeatures(draw, isLoaded),
                        A.map((f) =>
                            feature && f.id === feature.id
                                ? ({
                                      ...f,
                                      geometry: {
                                          ...f.geometry,
                                          coordinates:
                                              feature.geometry.coordinates,
                                      },
                                  } as GeoJSON.Feature<
                                      GeoJSON.Geometry,
                                      GeoJSON.GeoJsonProperties
                                  >)
                                : f
                        )
                    );

                    source.setData({ type: "FeatureCollection", features: f });
                    setPreviousState(f);
                }
            },
        });

        return () => {
            cleanup();
        };
    }, [map.current, draw.current, isLoaded]);

    return (
        <Stack
            gap={2}
            sx={{
                padding: 4,
            }}
        >
            {pipe(
                features,
                O.fromPredicate((s) => !isEmpty(s)),
                O.fold(
                    () => (
                        <EmptyState
                            icon={<LayersOutline />}
                            title={"No Shape Layers"}
                            subtitle={"Create shapes to see them here"}
                            border={false}
                        />
                    ),
                    (shapes) => {
                        return (
                            <Formik<MapLayerForm>
                                validateOnMount
                                enableReinitialize={!isSelectMode}
                                initialValues={{
                                    features: shapes,
                                    currentFeature: isDrawMode
                                        ? undefined
                                        : currentShape,
                                }}
                                onSubmit={(values, actions) => {
                                    submit(values);

                                    actions.setSubmitting(false);
                                }}
                            >
                                {({ values, setValues, handleSubmit }) => {
                                    const onChange = (
                                        i: Partial<MapLayerForm>
                                    ) => {
                                        setValues((p) => ({ ...p, ...i }));
                                    };

                                    const onDelete = (id: string) => {
                                        deleteShapeLayer(id, values);
                                        onChange({
                                            features: pipe(
                                                values.features,
                                                A.filter((v) => v.id !== id)
                                            ),
                                        });
                                    };

                                    const onMore = (feature?: MapFeature) => {
                                        onChange({ currentFeature: feature });

                                        exitMapModes();

                                        if (
                                            feature &&
                                            map.current &&
                                            isPolygonFeature(feature)
                                        ) {
                                            setTooltip({
                                                show: true,
                                                message: "Editing shape",
                                                coordinates:
                                                    getPolygonTooltipPosition({
                                                        polygon: feature,
                                                        map: map.current,
                                                    }),
                                            });
                                        }
                                    };

                                    const onHover = (
                                        feature: MapFeature,
                                        isHovered: boolean
                                    ) => {
                                        if (
                                            map.current &&
                                            isPolygonFeature(feature)
                                        ) {
                                            setTooltip({
                                                show: isHovered,
                                                message: "Shape",
                                                coordinates:
                                                    getPolygonTooltipPosition({
                                                        polygon: feature,
                                                        map: map.current,
                                                    }),
                                            });
                                        }
                                    };

                                    return pipe(
                                        values.currentFeature,
                                        O.fromPredicate((m) => !!m),
                                        O.fold(
                                            () => (
                                                <MapShapeLayersView
                                                    state={features}
                                                    values={values}
                                                    onDelete={onDelete}
                                                    onMore={onMore}
                                                    onChange={onChange}
                                                    onHover={onHover}
                                                />
                                            ),
                                            (currentFeature) => (
                                                <MapShapeLayerStyles
                                                    goBack={() => onMore()}
                                                    currentFeature={
                                                        currentFeature
                                                    }
                                                    draw={draw}
                                                    onChange={({
                                                        currentFeature,
                                                    }) => {
                                                        onChange({
                                                            currentFeature,
                                                            features: pipe(
                                                                values.features,
                                                                A.map((f) =>
                                                                    f.id ===
                                                                    currentFeature?.id
                                                                        ? {
                                                                              ...f,
                                                                              properties:
                                                                                  {
                                                                                      ...f.properties,
                                                                                      ...currentFeature?.properties,
                                                                                  },
                                                                          }
                                                                        : f
                                                                )
                                                            ),
                                                        });
                                                        handleSubmit();
                                                    }}
                                                />
                                            )
                                        )
                                    );
                                }}
                            </Formik>
                        );
                    }
                )
            )}
        </Stack>
    );
};

export default MapShapeLayers;
