import {
    DataSource,
    MapStyleType,
    MarketplaceDataset,
    PolygonProperties,
    SavedArea,
    useCreatePreviewDataSourceFromMarketplaceDatasetMutation,
    useDownloadMarketplaceDatasetPreviewMutation,
    useFetchAllAreasExtendedQuery,
    useFetchSavedViewsQuery,
    useFetchUnPreviewedMarketplaceDatasetsQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Box, SelectableTreeMenuItem, Severity } from "@biggeo/bg-ui/lab";
import { MapLayout, MapLayoutTabs } from "@biggeo/bg-ui/lab/layouts";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import compact from "lodash/compact";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isUndefined from "lodash/isUndefined";
import some from "lodash/some";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SetURLSearchParams } from "react-router-dom";
import { match as tsMatch } from "ts-pattern";
import OnMapLeave from "../../../common/components/OnMapLeave";
import { isAppRunningOnSF, isSnp } from "../../../common/redux/hooks";
import { Consumers } from "../../../common/redux/model";

import { ExploreDatasetsViewContainer } from "../../../marketplace/containers/ExploreDatasetsViewContainer";
import MarketplaceDataViewContainer from "../../../marketplace/containers/MarketplaceDataViewContainer";
import { toasterActions } from "../../../toaster/containers/redux/model";
import { snapToView } from "../../../utils/utils";
import DebugContainer from "../../DebugContainer";
import { MapLayoutLeftContentContainer } from "../../containers/MapLayoutLeftContentContainer";
import MapFilterCriteria from "../../filter-criteria/containers/MapFilterCriteria";

import { Either } from "fp-ts/lib/Either";
import uuid from "react-uuid";
import MarketplaceDatasetPreviewContainer from "../../../marketplace/containers/MarketplaceDatasetPreviewContainer";
import { CallBacksType } from "../../../utils/types";
import MapFilterCriteriaDatasets from "../../filter-criteria/containers/MapFilterCriteriaDatasets";
import { ViewDatasetTableContainer } from "../../filter-criteria/containers/ViewDatasetTableContainer";
import { setFilterCriteria } from "../../filter-criteria/utils/utils";
import MapTopbar from "../../filter-criteria/views/MapTopbar";
import { usePureDataString } from "../../hooks/pure-data-string-hook";
import { RenderControlsHookReturnType } from "../../hooks/render-data-hooks";
import { MapTabs } from "../../map-wrappers/MapViewWrapper";
import {
    FunctionType,
    SavedPolygonSource,
    convertSavedAreas,
    getConflictAreas,
    getMapFeatures,
    getPolygonsFromSavedAreas,
    handleFilterReset,
    handleSelectedAreas,
    pickGeospatialSelection,
} from "../../utils/utils";
import MapShapeLayers from "../../views/MapShapeLayers";
import MapStyles, { DEFAULT_MAP_STYLE } from "../../views/MapStyles";
import { SavedAreasSubmenu } from "../../views/SavedAreasSubmenu";
import { useMap } from "../context";
import { setDatasetContext } from "../context/context-utils";
import { MapboxMapContainer } from "./MapContainer";

export interface IMapMainContainer extends RenderControlsHookReturnType {
    readonly mapTemplateId: number;
    readonly activeConsumption: Consumers;
    readonly setConsumption: (consumption: Consumers) => void;
    readonly handleSideMenu?: (v: boolean) => void;
    readonly setOpenSaveViewPopper: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    readonly openSaveViewPopper: boolean;
    readonly selectedSavedView?: number;
    readonly tab?: MapTabs;
    readonly isFromMarketplace?: boolean;
    readonly savedAreaId: number;
    readonly savedViewId: number;
    readonly setSearchParams: SetURLSearchParams;
    readonly searchParams: URLSearchParams;
}

const MapMainContainer = ({
    mapTemplateId,
    isFromMarketplace = false,
    savedAreaId,
    savedViewId,
    searchParams,
    setSearchParams,
    ...props
}: IMapMainContainer) => {
    const {
        responses,
        multiFilters,
        addRemoveDataset,
        setMultiFilters,
        handleViewportChange,
        viewport,
        polygons,
        channelId,
        recentResponse,
        savedPolygons,
        setSavedPolygons,
        options,
        functionType,
        setFunctionType,
        clearShapes,
        handleMultiPolygons,
        addMultipleDatasets,
        deleteShape,
        setOptions,
        handlePolygonsOnZoom,
        handleSavedPolygons,
        datasetPointsShapes,
    } = usePureDataString({
        isSavedViewPage: !isEqual(savedViewId, 0),
    });

    const {
        map,
        isLoaded,
        previousMapState,
        selectedShapes,
        dispatch,
        draw,
        modes,
        filters,
        datasets,
    } = useMap();
    const _snp = isSnp();

    const {
        addDatasets,
        updateDataset,
        removeDataset,
        overrideDatasets,
        reorderDatasets,
    } = setDatasetContext();

    const isRunningOnSF = isAppRunningOnSF();

    const [tab, setTab] = useState<MapLayoutTabs>("datasets");

    const drawnPolygons = getMapFeatures(draw, isLoaded);

    const reduxDispatch = useDispatch();

    const handleSelectedShapes = (
        i:
            | GeoJSON.FeatureCollection<
                  GeoJSON.Geometry,
                  GeoJSON.GeoJsonProperties
              >
            | GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
    ) => {
        dispatch?.({
            type: "SET_SELECTED_SHAPES",
            values: i,
        });
    };

    const [reFetchSavedAreas, setReFetchSavedAreas] = useState<boolean>(false);

    const [mapStyle, setMapStyle] = useState<MapStyleType>(
        DEFAULT_MAP_STYLE.key
    );

    const [selectedAreaId, setSelectedAreaId] = useState<number | undefined>();
    const [selectedSavedView, setSelectedSavedView] = useState<
        number | undefined
    >();

    const [filterItems, setFilterItems] = useState<
        readonly SelectableTreeMenuItem[]
    >([]);

    const [openBottomSlot, setOpenBottomSlot] = useState(false);
    const [previewDataset, setPreviewDataset] = useState<
        | Either<{ marketplaceDatasetId: string }, { dataSourceId: string }>
        | undefined
    >(undefined);

    const {
        openFilterCriteria,
        setOpenFilterCriteria,
        canAddFilterCriteria,
        canViewFilteredDataTable,
        handleFilterCriteriaSidebar,
        setFilters,
        updateFilter,
        toggleFilterVisibility,
        removeFilter,
        clearFilters,
        editFilter,
        onAddFilter,
        handleFilterCriteria,
        saveFilter,
        addFilter,
    } = setFilterCriteria({
        dispatch,
        multiFilters,
        setMultiFilters,
        selectedDatasets: pipe(
            datasets,
            A.filter((d) => isEqual(d.isSelected, true))
        ),
        filters,
        setPreviewDataset,
    });

    const datasetViewed = pipe(
        datasets,
        A.findFirst((f) => isEqual(f.isTableViewed, true)),
        O.fold(
            () => undefined,
            (f) => f
        )
    );

    const isSavedAreaSelected = some(
        filterItems,
        (i) => !!i.selected || some(i.subItems, (s) => !!s.selected)
    );

    const getMapTab = (mapTab: string | null): MapLayoutTabs => {
        return tsMatch<string | null, MapLayoutTabs>(mapTab)
            .with("dataset", () => "datasets")
            .with("shapeLayers", () => "shapeLayers")
            .with("areas", () => "areas")
            .with("maps", () => "maps")
            .with("configuration", () => "configuration")
            .otherwise(() => "datasets");
    };

    const resetAreasFilters = () =>
        setFilterItems(handleFilterReset(filterItems));

    const {
        queryReturn: { data: savedAreasData, refetch: refetchSavedAreas },
    } = useFetchAllAreasExtendedQuery(
        tab === "areas"
            ? {
                  variables: {
                      fkMapTemplateId: mapTemplateId,
                  },
              }
            : { skip: true }
    );

    const {
        queryReturn: { refetch: refetchSavedViews },
    } = useFetchSavedViewsQuery({
        variables: { input: { limit: 100, pageIndex: 1 } },
        skip: true,
    });

    const handleSelectedSavedPolygons = (i: {
        savedAreas: SavedArea[];
        selectableItems: SelectableTreeMenuItem[];
        isConflict?: boolean;
    }) => {
        const { savedAreas, selectableItems, isConflict } = i;

        setSavedPolygons({
            source: SavedPolygonSource.savedArea,
            polygons: savedAreas,
            isConflict: isUndefined(isConflict)
                ? !isEmpty(savedAreas) && !isEmpty(drawnPolygons)
                : isConflict,
        });

        snapToView({
            polygons: getPolygonsFromSavedAreas(savedAreas),
            map,
        });

        setFilterItems(selectableItems);
    };

    const selectSavedArea = (i: {
        items: ReadonlyArray<SelectableTreeMenuItem>;
        skipConflicts?: boolean;
    }) => {
        const { items, skipConflicts = false } = i;

        setFilterItems(items);
        setFunctionType(FunctionType.savedPolygon);

        const selectedIds = pipe(
            items,
            toNonReadonlyArray,
            A.flatMap((item) =>
                pipe(
                    item.subItems,
                    toNonReadonlyArray,
                    A.filter((sub) => sub.selected),
                    A.map((sub) => sub.id)
                )
            )
        );

        const savedAreaPolygons = pipe(
            savedAreasData?.fetchAllAreasExtended,
            toNonReadonlyArray,
            A.flatMap(({ savedAreas }) =>
                pipe(
                    savedAreas,
                    A.filter((sa) => includes(selectedIds, sa.id))
                )
            )
        );

        if (skipConflicts) {
            setSavedPolygons({
                source: SavedPolygonSource.savedArea,
                polygons: savedAreaPolygons,
                isConflict: false,
            });
        } else {
            const conflicts =
                !isEmpty(drawnPolygons) && !isEmpty(savedAreaPolygons)
                    ? pipe(
                          drawnPolygons,
                          A.map((shape) =>
                              getConflictAreas({
                                  shape,
                                  savedAreas: savedAreaPolygons,
                              })
                          ),
                          A.map(({ intersection }) => intersection),
                          compact
                      )
                    : [];

            setSavedPolygons({
                source: SavedPolygonSource.savedArea,
                polygons: savedAreaPolygons,
                isConflict: !isEmpty(conflicts),
            });
        }

        // Delete unselected area from polygons & refetch
        if (isEmpty(drawnPolygons)) {
            const selectedPolygonIds = pipe(
                items,
                toNonReadonlyArray,
                A.filter((item) => item.selected),
                A.concat(
                    pipe(
                        items,
                        toNonReadonlyArray,
                        A.flatMap((item) =>
                            pipe(
                                item.subItems,
                                toNonReadonlyArray,
                                A.filter((sub) => sub.selected)
                            )
                        )
                    )
                ),
                A.mapWithIndex((index, { id }) => `${id}-${index}`)
            );

            pipe(
                polygons,
                A.map((item) => {
                    if (!selectedPolygonIds.includes(item.id)) {
                        deleteShape(item.id);
                    }
                })
            );
        }
    };

    const selectSavedAreas = (
        savedAreas: Pick<PolygonProperties, "name" | "areaId" | "savedAreaId">[]
    ) => {
        const savedAreaIds = pipe(
            savedAreas,
            A.map((s) => s.savedAreaId),
            compact
        );

        if (!isEmpty(savedAreaIds)) {
            const currentSelectedIds = pipe(
                filterItems,
                toNonReadonlyArray,
                A.flatMap((item) =>
                    pipe(
                        item.subItems,
                        toNonReadonlyArray,
                        A.filter((sub) => sub.selected),
                        A.map((sub) => sub.id)
                    )
                )
            );

            if (!isEqual(savedAreaIds, currentSelectedIds)) {
                selectSavedArea({
                    skipConflicts: true,
                    items: handleSelectedAreas({
                        ids: savedAreaIds,
                        type: "subItem",
                        items: filterItems,
                    }),
                });
            }
        }
    };

    const resizeMap = () => {
        window.setTimeout(
            () => map.current && isLoaded && map.current.resize(),
            400
        );
    };

    const exitMapModes = (tab: MapLayoutTabs) => {
        if (tab !== "datasets") {
            setRefetchDatasourcePreview(true);
        }
        if (tab === "shapeLayers") {
            setFunctionType(FunctionType.viewport);
            draw.current?.changeMode("simple_select");

            if (modes && isEqual(modes.select.isSelectMode, true)) {
                modes.select.selectMode(false);
                draw.current?.add({
                    type: "FeatureCollection",
                    features: pipe(
                        getMapFeatures(draw, isLoaded),
                        A.map((f) => ({
                            ...f,
                            properties: {
                                ...f.properties,
                                selected: false,
                            },
                        }))
                    ),
                });
            }
        }
    };

    const exploreDataset = () => {
        setOpenBottomSlot(true);
    };
    const onClickPreviewInfo = (
        input?: Either<
            { marketplaceDatasetId: string },
            { dataSourceId: string }
        >
    ) => {
        setPreviewDataset(input);
        setOpenFilterCriteria(false);
    };

    const [refetchDatasourcePreview, setRefetchDatasourcePreview] =
        useState<boolean>(true);

    const [isPreviewBtnDisabled, setIsPreviewBtnDisabled] =
        useState<boolean>(false);

    const {
        queryReturn: {
            loading: mpDatasetsLoading,
            data: mpDatasetsData,
            refetch: refetchUnPreviewedMarketplaceDatasets,
        },
    } = useFetchUnPreviewedMarketplaceDatasetsQuery(
        isRunningOnSF
            ? {
                  variables: {
                      input: {},
                  },
              }
            : { skip: true }
    );

    const { executeMutation: createDatasourceMutation } =
        useCreatePreviewDataSourceFromMarketplaceDatasetMutation();

    const { executeMutation: downloadPreviewDataset } =
        useDownloadMarketplaceDatasetPreviewMutation();

    const initiatePreviewDatasetDownload = (
        dataset: MarketplaceDataset,
        onSuccess: () => void
    ) => {
        downloadPreviewDataset({
            variables: {
                input: {
                    collectionName: dataset.collectionName,
                    dataSourceId: dataset.id,
                    isRunningOnSF: isRunningOnSF,
                    path: dataset.path,
                },
            },
            onError: (error) => {
                reduxDispatch(
                    toasterActions.openToast({
                        open: true,
                        title: `Error downloading preview dataset: ${error.message}`,
                        autoHideDuration: 5000,
                        severity: Severity.error,
                    })
                );
            },
            onCompleted: () => {
                refetchUnPreviewedMarketplaceDatasets();
                onSuccess();
            },
        });
    };

    const onClickPreviewDataset = (
        marketplaceDatasetId: string,
        callbacks?: CallBacksType<DataSource>
    ) => {
        setIsPreviewBtnDisabled(true);
        const dataset: MarketplaceDataset | undefined =
            mpDatasetsData?.fetchUnPreviewedMarketplaceDatasets.marketplaceDatasets.find(
                (d) => d.id === marketplaceDatasetId
            );
        if (dataset) {
            createDatasourceMutation({
                variables: {
                    input: {
                        id: uuid(),
                        fkMarketplaceDatasetId: dataset.id,
                        size: dataset.size,
                        isMipmapped: false,
                        tableName: dataset.tableName,
                        collectionName: dataset.collectionName,
                        geographyColumn: dataset.geographyColumn,
                        mapCost: dataset.price,
                        description: dataset.description,
                        label: dataset.name,
                        color: "#0000FF",
                        compute: false,
                        sfAlias: "",
                        isLoading: false,
                        type: dataset.type,
                        tableId: dataset.tableId,
                        isConnected: false,
                    },
                },
                onError: (err) => {
                    reduxDispatch(
                        toasterActions.openToast({
                            open: true,
                            title: "Dataset preview failed",
                            autoHideDuration: 5000,
                            severity: Severity.error,
                        })
                    );
                    callbacks?.onError?.(err.message);
                },
                onCompleted: (data) => {
                    const previewedDataset =
                        data.createPreviewDataSourceFromMarketplaceDataset;
                    setRefetchDatasourcePreview(true);
                    setIsPreviewBtnDisabled(false);

                    initiatePreviewDatasetDownload(dataset, () =>
                        callbacks?.onSuccess?.(previewedDataset)
                    );
                },
            });
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependency needed
    useEffect(() => {
        if (reFetchSavedAreas) {
            refetchSavedAreas?.();
            setReFetchSavedAreas?.(false);
        }
    }, [reFetchSavedAreas]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependency needed
    useEffect(() => {
        if (
            !isSavedAreaSelected &&
            !isEmpty(savedPolygons.polygons) &&
            !savedViewId
        ) {
            setFunctionType(FunctionType.viewport);
            clearShapes();
            setSavedPolygons({
                source: SavedPolygonSource.savedArea,
                polygons: [],
                isConflict: false,
            });
        }
    }, [isSavedAreaSelected, filterItems, savedAreasData]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const canSetSavedAreaItems =
            !previousMapState ||
            previousMapState?.selectedSavedAreas.savedAreas.length === 0;

        if (canSetSavedAreaItems) {
            refetchSavedAreas();

            const data = convertSavedAreas(
                savedAreasData?.fetchAllAreasExtended || [],
                selectedAreaId
            );

            if (savedAreaId) {
                setTimeout(() => {
                    selectSavedArea({
                        items: handleSelectedAreas({
                            ids: [savedAreaId],
                            type: "subItem",
                            items: data,
                        }),
                    });
                }, 250);
            }

            setFilterItems(!isEmpty(data) ? data : []);
        }
    }, [savedAreasData, selectedAreaId, reFetchSavedAreas]);

    const resetCanViewFilteredDataTable = () => {
        filters.map((filter) =>
            dispatch?.({
                type: "UPDATE_FILTER",
                values: { id: filter.id, viewed: false },
            })
        );
    };

    const onViewTable = (dataSourceId: string, state: boolean) => {
        resetCanViewFilteredDataTable();
        updateDataset({
            dataSourceId,
            dataset: {
                isTableViewed: !state,
            },
        });
    };

    return (
        <OnMapLeave>
            <MapLayout
                sidebarOpen
                leftSubPanelOpen={false}
                bottomSize={
                    canViewFilteredDataTable || !!datasetViewed
                        ? 500
                        : undefined
                }
                rightOpen={openFilterCriteria || !!previewDataset}
                topOpen={canAddFilterCriteria}
                bottomOpen={
                    canViewFilteredDataTable ||
                    openBottomSlot ||
                    !!datasetViewed
                }
                mapLayoutTab={getMapTab(searchParams.get("mapTab"))}
                right={
                    openFilterCriteria ? (
                        <MapFilterCriteria
                            map={map}
                            isLoaded={isLoaded}
                            isOpen={openFilterCriteria}
                            mapTemplateId={mapTemplateId}
                            handleSidebar={handleFilterCriteria}
                            addFilter={addFilter}
                            updateFilter={updateFilter}
                            saveFilter={saveFilter}
                            currentFilter={pipe(
                                filters,
                                A.findFirst((f) => isEqual(f.selected, true)),
                                O.fold(
                                    () => undefined,
                                    (f) => f
                                )
                            )}
                            setMultiFilters={setMultiFilters}
                            resizeMap={resizeMap}
                            selectedDatasets={pipe(
                                datasets,
                                A.filter((d) => isEqual(d.isSelected, true))
                            )}
                        />
                    ) : previewDataset ? (
                        <MarketplaceDatasetPreviewContainer
                            previewDataset={previewDataset}
                            onClickPreviewDataset={onClickPreviewDataset}
                            onClickPreviewInfo={onClickPreviewInfo}
                            isPreviewBtnDisabled={isPreviewBtnDisabled}
                        />
                    ) : undefined
                }
                top={
                    <MapTopbar
                        onAddFilter={() => onAddFilter(map, isLoaded)}
                        clearFilters={() => clearFilters(map, isLoaded)}
                        removeFilter={(id) =>
                            removeFilter({
                                id,
                                map,
                                isLoaded,
                            })
                        }
                        filters={filters}
                        editFilter={editFilter}
                        viewFilterTable={(id, isViewed) => {
                            updateFilter({ id, viewed: !isViewed });
                            handleFilterCriteriaSidebar(false);
                        }}
                        responses={responses}
                        recentResponse={recentResponse}
                        viewport={viewport}
                        multipolygon={polygons}
                        multiFilters={multiFilters}
                        toggleFilterVisibility={(id, visible) =>
                            toggleFilterVisibility(id, visible, map, isLoaded)
                        }
                    />
                }
                bottom={
                    canViewFilteredDataTable ? (
                        <MapFilterCriteriaDatasets
                            filters={filters}
                            handleClose={(id) => {
                                resizeMap();
                                updateFilter({ id, viewed: undefined });
                                resetCanViewFilteredDataTable();
                            }}
                            channelId={channelId}
                            functionType={functionType}
                            viewport={viewport}
                            polygons={polygons}
                        />
                    ) : datasetViewed ? (
                        <ViewDatasetTableContainer
                            dataset={{
                                dataSourceId: datasetViewed.dataSource.id,
                                type: datasetViewed.dataSource.type,
                                collection:
                                    datasetViewed.dataSource.collectionName,
                                label:
                                    datasetViewed.dataSource.label ||
                                    datasetViewed.dataSource.tableName,
                            }}
                            channelId={channelId}
                            onClose={() => {
                                onViewTable(
                                    datasetViewed.dataSource.id,
                                    datasetViewed.isTableViewed
                                );
                                resizeMap();
                            }}
                            geospatialSelection={pickGeospatialSelection(
                                functionType,
                                {
                                    viewport,
                                    multipolygon: polygons.map((c) => ({
                                        inners: c.inners,
                                        outer: c.outer,
                                    })),
                                }
                            )}
                        />
                    ) : openBottomSlot ? (
                        <MarketplaceDataViewContainer
                            onClose={() => {
                                setOpenBottomSlot(false);
                                resizeMap();
                            }}
                            onClickPreviewDataset={onClickPreviewDataset}
                            mpDatasetsData={mpDatasetsData}
                            mpDatasetsLoading={mpDatasetsLoading}
                            onClickPreviewInfo={onClickPreviewInfo}
                            refetchUnPreviewedMarketplaceDatasets={
                                refetchUnPreviewedMarketplaceDatasets
                            }
                            isRunningOnSF={isRunningOnSF}
                        />
                    ) : undefined
                }
                leftSubPanel={
                    <ExploreDatasetsViewContainer
                        exploreDataset={exploreDataset}
                        onClickPreviewInfo={onClickPreviewInfo}
                        refetchDatasourcePreview={refetchDatasourcePreview}
                        setRefetchDatasourcePreview={
                            setRefetchDatasourcePreview
                        }
                        addMultipleDatasets={addMultipleDatasets}
                        addRemoveDataset={addRemoveDataset}
                        updateDataset={updateDataset}
                        addDatasets={addDatasets}
                        isSavedViewPage={!isEqual(savedViewId, 0)}
                        reorderDatasets={reorderDatasets}
                    />
                }
                datasets={
                    <MapLayoutLeftContentContainer
                        map={map}
                        isLoaded={isLoaded}
                        addRemoveDataset={addRemoveDataset}
                        mapTemplateId={mapTemplateId}
                        isFromMarketplace={isFromMarketplace}
                        onViewTable={onViewTable}
                        multiFilters={multiFilters}
                        setMultiFilters={setMultiFilters}
                        addDatasets={addDatasets}
                        updateDataset={updateDataset}
                        removeDataset={removeDataset}
                        isSavedViewPage={!isEqual(savedViewId, 0)}
                        overrideDatasets={overrideDatasets}
                        reorderDatasets={reorderDatasets}
                    />
                }
                shapeLayers={
                    <MapShapeLayers
                        deleteShape={deleteShape}
                        functionType={functionType}
                        exitMapModes={() => exitMapModes("shapeLayers")}
                    />
                }
                areas={
                    <SavedAreasSubmenu
                        filterItems={filterItems}
                        selectSavedArea={(items) => selectSavedArea({ items })}
                    />
                }
                maps={
                    <MapStyles
                        isLoaded={isLoaded}
                        map={map}
                        currentStyle={
                            map.current && isLoaded
                                ? map.current.getStyle()
                                : undefined
                        }
                        onStyleChange={(style) => setMapStyle(style)}
                    />
                }
                configuration={
                    <DebugContainer
                        options={options}
                        setOptions={setOptions}
                        showTriangles={props.showTriangles}
                        setShowTriangles={props.setShowTriangles}
                        showPoints={props.showPoints}
                        setShowPoints={props.setShowPoints}
                        showFiltered={props.showFiltered}
                        setShowFiltered={props.setShowFiltered}
                    />
                }
                onSidebarOpenChange={(_, tab) => {
                    resizeMap();
                    exitMapModes(tab);
                    setTab(tab);
                }}
                onSidebarIconClick={(_, tab) => {
                    exitMapModes(tab);
                    setTab(tab);
                }}
                onSidebarResizeEnd={() => resizeMap()}
                onTopOpenChange={() => resizeMap()}
                onTopResizeEnd={() => resizeMap()}
                onRightOpenChange={() => resizeMap()}
                onRightResizeEnd={() => resizeMap()}
                onBottomOpenChange={() => resizeMap()}
                onBottomResizeEnd={() => resizeMap()}
            >
                <Box
                    height={"100%"}
                    width={"100%"}
                    sx={{
                        padding: 4,
                        overflow: "auto",
                        borderRadius: (theme) => theme.radius.xs3,
                    }}
                >
                    <MapboxMapContainer
                        key={Consumers.mapbox}
                        {...props}
                        isFromMarketplace={isFromMarketplace}
                        setOptions={setOptions}
                        deleteShape={deleteShape}
                        handleSavedPolygons={handleSavedPolygons}
                        datasetPointsShapes={datasetPointsShapes}
                        handlePolygonsOnZoom={handlePolygonsOnZoom}
                        isFilterCriteriaOpen={openFilterCriteria}
                        filters={filters}
                        setFilters={setFilters}
                        addMultipleDatasets={addMultipleDatasets}
                        functionType={functionType}
                        multiFilters={multiFilters}
                        recentResponse={recentResponse}
                        responses={responses}
                        viewport={viewport}
                        handleViewportChange={handleViewportChange}
                        savedPolygons={savedPolygons}
                        options={options}
                        setMultiFilters={setMultiFilters}
                        channelId={channelId}
                        setFunctionType={setFunctionType}
                        setSavedPolygons={setSavedPolygons}
                        polygons={polygons}
                        handleMultiPolygons={handleMultiPolygons}
                        clearShapes={clearShapes}
                        addRemoveDataset={addRemoveDataset}
                        setSelectedAreaId={setSelectedAreaId}
                        selectedSavedView={selectedSavedView}
                        setSelectedSavedView={setSelectedSavedView}
                        refetchSavedAreas={refetchSavedAreas}
                        refetchSavedViews={refetchSavedViews}
                        setReFetchSavedAreas={setReFetchSavedAreas}
                        resetAreasFilters={resetAreasFilters}
                        handleSelectedSavedPolygons={
                            handleSelectedSavedPolygons
                        }
                        selectableItems={pipe(filterItems, toNonReadonlyArray)}
                        selectedShapes={selectedShapes}
                        handleSelectedShapes={handleSelectedShapes}
                        savedAreaId={savedAreaId}
                        savedViewId={savedViewId}
                        setSearchParams={setSearchParams}
                        searchParams={searchParams}
                        mapTemplateId={mapTemplateId}
                        mapStyle={mapStyle}
                        configurationControls={{
                            showTriangles: props.showTriangles,
                            setShowTriangles: props.setShowTriangles,
                            showPoints: props.showPoints,
                            setShowPoints: props.setShowPoints,
                            showFiltered: props.showFiltered,
                            setShowFiltered: props.setShowFiltered,
                        }}
                        addDatasets={addDatasets}
                        updateDataset={updateDataset}
                        removeDataset={removeDataset}
                        overrideDatasets={overrideDatasets}
                        selectSavedAreas={selectSavedAreas}
                        reorderDatasets={reorderDatasets}
                    />
                </Box>
            </MapLayout>
        </OnMapLeave>
    );
};

export default MapMainContainer;
